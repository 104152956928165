@import '../../style/colors.scss';

.account_wrapper {
    position: relative;
    width: 100%;
    min-height: calc(100vh - 120px);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
    
    @media screen and (max-width: 1080px) {
        flex-direction: column;
        padding: 0;
        width: 100%;
    }

    .menu_block {
        position: sticky;
        top: 20px;
        left: 0;
        width: 20%;

        @media screen and (max-width: 1080px) {
            flex-direction: row;
            width: 100%;
            position: relative;
            min-height: unset;
            padding: 10px 16px;
            box-sizing: border-box;

            div {
                display: flex;
                align-items: center;
            }
        }

        .admin_name {
            font-size: 20px;
            color: $main_blue;
            font-weight: 700;
            text-transform: uppercase;
            padding-top: 10px;
            padding-bottom: 10px;
        }

        .menu {
            margin-right: 20px;
            background-color: $main_blue;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 6px 20px 20px;
            min-height: calc(100vh - 120px);

            @media screen and (max-width: 1080px) {
                flex-direction: row;
                width: 100%;
                position: relative;
                min-height: unset;
                padding: 10px 16px;
                box-sizing: border-box;

                div {
                    display: flex;
                    align-items: center;
                }
            }

            .item {
                display: flex;
                align-items: center;
                padding: 14px 0;
                border-bottom: 1px solid $border_color;
                text-decoration: none;

                @media screen and (max-width: 1080px) {
                    padding: 0;
                }

                @media screen and (max-width: 1080px) {
                    flex-direction: column;
                    border-bottom: none;
                    
                    &:not(:last-child) {
                        margin-right: 14px;
                    }
                }

                @media screen and (max-width: 520px) {
                    &:not(:last-child) {
                        margin-right: 10px;
                    }
                }

                &.active,
                &:hover {
                    cursor: pointer;

                    // svg {
                    //     fill: $main_green;
                    // }

                    span {
                        color: $main_green;
                    }
                }
                
                svg {
                    margin-right: 10px;
                    width: 20px;
                    height: 20px;
                    // fill: rgb(218, 29, 29) !important;
                    transition: fill .2s;

                    @media screen and (max-width: 1080px) {
                        margin-bottom: 5px;
                        margin-right: 0;
                    }
                }

                span {
                    color: $white;
                    font-size: 16px;
                    font-weight: 600;
                    transition: color .2s;

                    @media screen and (max-width: 1080px) {
                        font-size: 12px;
                    }

                    @media screen and (max-width: 520px) {
                        font-size: 9px;
                    }
                }
            }
        }
        
    }
}

.inputs_wrapper {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    width: 100%;

    .disabledItem {
        opacity: 0.5; 
        pointer-events: none; 
    }

    .item {
        display: flex;
        flex-direction: column;
        width: 100%;
        
        &:not(:last-child) {
            margin-bottom: 20px;
        }

        label {
            color: $white;
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 10px;

            @media screen and (max-width: 520px) {
                font-size: 14px;
            }
        }

        input {
            height: 19px;
            border-radius: 3px;
            outline: none;
            border: none;
            background-color: $white;
            padding: 13px 10px;
            color: $main_blue;
            font-family: Raleway;
            font-size: 16px;

            @media screen and (max-width: 520px) {
                font-size: 14px;
            }
        }

        .input_container {
            display: flex;
            align-items: center;

            &:not(:last-child) {
                margin-bottom: 5px;
            }
            
            input {
                width: 100%;
                max-width: 70px;
                margin-right: 10px;
            }

            span {
                color: $white;
            }
        }
    }
}

.admin_container {
    //position: relative;
    overflow-y: auto;
    width: calc(80% - 20px);
    min-height: calc(100vh - 120px);
    max-height: 100vh;
    padding: 20px;
    background-color: $main_blue;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @media screen and (max-width: 1080px) {
        width: 100%;
        margin-top: 36px;
        box-sizing: border-box;
        padding: 20px 16px;
        max-height: auto;
    }

    .page_name {
        color: $white;
        font-size: 20px;
        font-weight: 500;

        @media screen and (max-width: 520px) {
            font-size: 14px;
        }
    }

    .account_img {
        position: relative;
        margin-bottom: 50px;

        .main_img {
            border-radius: 80px;
            border: 2px solid $main_green;
            width: 80px;
            height: 80px;
            object-fit: contain;
            object-position: center;

            @media screen and (max-width: 520px) {
                width: 50px;
                height: 50px;
            }
        }

        .arrow {
            position: absolute;
            top: 50%;
            right: -25px;
            transform: translateY(-50%);
            transition: opacity .2s;

            &:hover {
                cursor: pointer;
                opacity: .8;
            }
        }
    }

    .action {
        text-align: center;
        color: $main_green;
        text-decoration-line: underline;
        margin-top: 10px;
        transition: color .2s;

        @media screen and (max-width: 520px) {
            font-size: 14px;
        }

        &:hover {
            color: $main_green_hover;
        }
    }
}

.container {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .name {
        color: $white;
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 20px;
    }

    &.preferences_container {
        justify-content: flex-start;
        flex-direction: row; 
        align-items: flex-start;

        @media screen and (max-width: 780px) {
            flex-direction: column;
        }

        .left_column {
            width: 30%;
            display: flex;
            flex-direction: column;
            margin-right: 100px;

            @media screen and (max-width: 780px) {
                width: 100%;
                margin-right: 0;
                margin-bottom: 16px;
                max-width: unset;
            }
        }

        .preferences_save {
            position: fixed;
            right: 50px;
            bottom: 20px;
        }

        .right_column {
            display: flex;
            flex-wrap: wrap;
            width: calc(70% - 100px);

            @media screen and (max-width: 780px) {
                width: 100%;
            }

            label {
                width: 45%;
                color: $white;

                span {
                    &:last-child {
                        &:first-letter {
                            text-transform: uppercase;
                        }
                    }
                }
            }

            .inputs_wrapper {
                width: 100%;
                max-width: 500px;
                margin-bottom: 10px;

                &:not(:first-child) {
                    margin-top: 30px;
                }
            }

            .input_block {
                width: 100%;
                position: relative;
                margin-top: 10px;

                input {
                    width: 100%;
                    box-sizing: border-box;
                    height: 45px;
                    padding-right: 40px;
                }

                img {
                    position: absolute;
                    right: 10px;
                    top: 50%;
                    transform: translateY(-50%);
                }

                .autocomplete_list {
                    position: absolute;
                    background-color: white;
                    border: 1px solid #ccc;
                    width: 100%;
                    z-index: 1000;
                    padding: 0;      
                    margin: 0;       
                    list-style: none;
                    max-height: 300px;
                    overflow-y: auto;
                  
                    li {
                      padding: 10px;
                      cursor: pointer;
                  
                      &:hover {
                        background-color: #f0f0f0;
                      }
                    }
                  }
            }

            .item_wrapper {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-direction: row;
                
                label {
                    width: fit-content;
                    margin-right: 10px;
                    margin-bottom: 0;
                }

                button {
                    padding: 0;
                    margin: 0;
                    width: fit-content;
                    height: fit-content;
                    background-color: transparent;
                    border: none;
                    height: 20px;
                    transition: opacity .2s;

                    &:hover {
                        opacity: .8;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

.navigation_block {
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 720px) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.user_list {
    align-items: flex-start;

    .item {
        display: flex;
        align-items: center;
    }

    .right_block {
        display: flex;
        flex-direction: column;
    }

    .info_text {
        color: #fff;
        padding-top: 10px;
        font-size: 12px;
    }

    .input_block {
        max-width: 300px;
        width: 100%;
        position: relative;
        margin-top: 10px;
        height: 45px;
        align-self: flex-end;

        input {
            width: 100%;
            box-sizing: border-box;
            height: 45px;
            padding-right: 40px;
        }

        img {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .letter_wrapper {
        max-width: 260px;
        display: flex;
        flex-wrap: wrap;
        margin: 20px -4px 0;

        span {
            display: inline-block;
            margin: 0 4px;
            color: $white;
            font-size: 20px;
            font-weight: 400;
        }
    }

    .setting_Wrapper {
        width: 100%;
        color: $white;
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media screen and (max-width: 720px) {
            flex-direction: column;
            align-items: flex-start;
        }

        .deselect {
            display: flex;
            align-items: center;
            font-size: 16px;
            font-weight: 600;
            text-decoration-line: underline;
            margin-bottom: 2px;
            cursor: pointer;

            img {
                margin-right: 5px;
            }
        }

        label {
            span {
                &:last-child {
                    font-family: Raleway;
                    font-size: 16px;
                    font-weight: 600;
                }
            }
        }
    }

    .dropdown_button {
        margin-left: 20px;

        &.filter_dropdown {
            button {
                @media screen and (max-width: 720px) {
                    padding-left: 0;
                }
            }
        }

        @media screen and (max-width: 720px) {
            margin-left: 0;
        }

        button {
            display: flex;
            align-items: center;

            span {
                margin: 0 10px;
            }

            img {
                transition: transform .2s;
            }
        }
    }
}

.button_img {
    width: 20px;
    height: 20px;
    padding-right: 5px;
}

.user_table {
    width: 100%;
    margin-top: 0;

    .total_label {
        padding-top: 5px;
        padding-bottom: 5px;
        span {
            color: #fff;
            font-size: 18px;
            font-weight: 600;
        }
        
    }

    table {
        border-collapse: separate;
        border-spacing: 0 20px;
    }

    & > div {
        background-color: transparent;
    }

    th, td {
        font-family: Raleway;
        border-bottom: none;
    }

    &.checkbox_table {
        thead {
            th {   
                @media screen and (max-width: 920px) {
                    &:last-child {
                        display: table-cell;
                    }
                }
            }
        }

        tbody {
            tr {
                td {
                    &:last-child {
                        background: none;
                        padding-left: 10px;
                        padding-right: 10px;
                    }

                    @media screen and (max-width: 920px) {
                        &:last-child {
                            display: table-cell;
                            padding-right: 0;
                            padding-left: 6px;
                        }
                    }
    
                }
            }
        }
    }

    thead {
        th {
            color: $white;
            padding: 3px 3px 0;

            @media screen and (min-width: 921px) {
                &:nth-child(3) {
                    display: none;
                }
            }

            @media screen and (max-width: 920px) {
                display: none;
                
                &:nth-child(-n + 3) {
                    display: table-cell;
                }
            }
        }
    }

    tbody {
        tr {
            transition: opacity .2s;

            &:hover {
                cursor: pointer;
                opacity: .9;
            }

            td {
                padding: 3px;
                color: $white;
                background: #37335C;

                &:first-child {
                    border-top-left-radius: 3px;
                    border-bottom-left-radius: 3px;
                }
                
                &:nth-child(7) {
                    border-top-right-radius: 3px;
                    border-bottom-right-radius: 3px;
                }

                @media screen and (min-width: 921px) {
                    &:nth-child(3) {
                        display: none;
                    }
                }

                @media screen and (max-width: 920px) {
                    display: none;
                    padding: 10px;
                    
                    &:nth-child(-n + 3) {
                        display: table-cell;
                    }


                    &:nth-child(3) {
                        text-align: right;
                        padding-right: 0;
                        padding-left: 0;

                        svg {
                            fill: $white;
                        }
                    }
                }

            }
        }
    }

    .table_dropdown_wrapper {
        @media screen and (min-width: 921px) {
            display: none !important;
        }

        td {
            padding: 0 !important;
        }
    }

    .table_dropdown {
        padding: 10px 16px;
        background-color: #37335C;
        margin-top: -30px;

        .item_wrapper {
            display: flex;
            justify-content: space-between;
            padding: 10px 0;
            border-top: 1px solid rgba(255, 255, 255, 0.30);

            &:last-child {
                padding-bottom: 0;
            }
        }

        .item {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            &.link {
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;

                span {
                    color: $main_green;
                    font-size: 14px;
                    font-weight: 600;
                    text-decoration-line: underline;
                    transition: color .2s;

                    &:hover {
                        color: $main_green_hover;
                    }
                }
            }
        }

        .item_name {
            color: rgba(255, 255, 255, 0.50);
            font-size: 14px;
            font-weight: 600;
            display: inline-block;
            margin-bottom: 5px;
        }

        .info {
            color: $white;
            font-size: 14px;
            font-weight: 400;
        }
    }
}

.close_button {
    position: absolute;
    width: 30px;
    height: 30px;
    padding: 10x;
    right: 30px;
    top: 40px;
    transition: opacity .2s;

    &:hover {
        cursor: pointer;
        opacity: .9;
    }
}

.user {
    align-items: flex-start;
    margin-bottom: 0;
    //position: relative;

    .filter {
        width: 100%;
        justify-content: flex-end;
        display: flex;
        transition: opacity .2s;

        &:hover {
            cursor: pointer;
            opacity: .9;
        }
    }

    .user_table {
        tbody {
            tr {
                td {
                    padding-top: 4px;
                    padding-bottom: 4px;

                    &:first-child {
                        border-top-left-radius: 3px;
                        border-bottom-left-radius: 3px;
                    }
                    
                    &:last-child {
                        border-top-right-radius: 3px;
                        border-bottom-right-radius: 3px;
                        background: #37335C;
                        padding-left: 16px;
                        padding-right: 16px;
                    }
                }
            }
        }
    }

    .account_navigation {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .item {
            padding: 10px;
            background: #35315A;
            transition: opacity .2s;

            @media screen and (max-width: 520px) {
                padding: 8px;
            }

            &:hover {
                cursor: pointer;
                opacity: .9;
            }
            
            &.active {
                background-color: $main_green;

                span {
                    color: $main_blue;
                    font-weight: 700;
                }
            }

            span {
                color: rgba(255, 255, 255, 0.50);
                font-size: 16px;
                font-weight: 500;

                @media screen and (max-width: 520px) {
                    font-size: 14px;
                }
            }

            &:first-child {
                border-top-left-radius: 3px;
                border-bottom-left-radius: 3px;
            }
            
            &:last-child {
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;
            }
        }
    }

    .user_info {
        width: 100%;
        display: flex;

        @media screen and (max-width: 590px) {
            flex-direction: column;
        }

        .account_img {
            margin-bottom: 20px;
        }

        .left_column {
            width: 100%;
            max-width: 300px;

            @media screen and (max-width: 590px) {
                max-width: unset;
            }
        }

        .info {
            color: $main_green;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.3;

            .special {
                text-decoration: underline;
            }

            .subtext {
                color: rgba(255, 255, 255, 0.50);
            }
        }
    }

    .inputs_wrapper {
        @media screen and (max-width: 590px) {
            max-width: unset;
        }
    }

    .send_link {
        border-radius: 3px;
        border: 1px solid $main_green;
        background: #37335C;
        padding: 13px;
        width: 100%;
        margin-bottom: 10px;

        color: $white;
        font-size: 16px;
        font-weight: 600;
        transition: opacity .2s;

        &:hover {
            cursor: pointer;
            opacity: .9;
        }
    }

    .center_column {
        margin-left: 60px;
        width: 100%;
        max-width: 300px;

        @media screen and (max-width: 590px) {
            max-width: unset;
            margin-left: 0;
            margin-top: 16px;
        }
    }

    .action_wrapper {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;

        @media screen and (max-width: 520px) {
            padding-bottom: 50px;
        }
    }

    .secondary_btn {
        display: flex;
        align-items: center;
        padding: 5px 20px;
        margin-right: 20px;

        transition: opacity .2s;

        @media screen and (max-width: 590px) {
            margin-right: 10px;
            padding: 5px 10px;
        }

        &:hover {
            cursor: pointer;
            opacity: .9;
        }

        img {
            margin-right: 10px;
        }

        span {
            color: $white;
            font-size: 16px;
            font-weight: 600;
            text-decoration-line: underline;

            &.red {
                color: $delete;
            }
        }
    }

    .main_button {
        padding: 10px 24px;
        border-radius: 3px;
        background-color: $main_green;
        margin-right: 20px;
        border: none;

        @media screen and (max-width: 590px) {
            margin-right: 0;
        }

        @media screen and (max-width: 520px) {
            position: fixed;
            right: 16px;
            bottom: 16px;
        }

        color: $main_blue;
        font-size: 16px;
        font-weight: 700;

        transition: background-color .2s;

        &:hover {
            cursor: pointer;
            background-color: $main_green_hover;
        }
    }
}

.letter {
    cursor: pointer;
}

.selected_letter {
    color: $main_green !important;
    font-weight: 700 !important;
}

.module {
    position: absolute;
    left: 0;
    top: -70px;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.500);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;

    .module_wrapper {
        max-width: 426px;
        background-color: #050033;
        border: 1px solid #FF5252;
        border-radius: 5px;
        padding: 32px 20px 20px;

        .content {
            margin-bottom: 20px;
            position: relative;

            img {
                position: absolute;
                left: 0;
                right: 0;
                width: 30px;
                height: 30px;
            }

            span {
                display: inline-block;
                margin-left: 40px;
                font-weight: 600;
                font-size: 16px;
                color: $white;

                &.red {
                    color: #FF5252;
                    margin-left: 0;
                }
            }
        }

        .info {
            padding-top: 10px;
            padding-bottom: 20px;
            color: #fff;
        }

        .action_block {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            button {
                padding: 10px 16px;
                border: none;
                border-radius: 3px;
                width: fit-content;
                font-size: 16px;
                font-weight: 700;
                font-family: 'Raleway';
                transition: opacity .2s;

                &:hover {
                    cursor: pointer;
                    opacity: .8;
                }

                &.left {
                    background-color: $white;
                }

                &.right {
                    background-color: #FF5252;
                    color: $white;
                    margin-left: 20px;
                }
            }
        }
    }
}

.inputs_wrapper {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    width: 100%;

    .header {
        font-size: 20px;
        font-weight: 600;
        color: #fff;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .item {
        display: flex;
        flex-direction: column;
        width: 100%;
        
        &:not(:last-child) {
            margin-bottom: 20px;
        }

        label {
            color: $white;
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 10px;

            @media screen and (max-width: 520px) {
                font-size: 14px;
            }
        }

        input {
            height: 19px;
            border-radius: 3px;
            outline: none;
            border: none;
            background-color: $white;
            padding: 13px 10px;
            color: $main_blue;
            font-family: Raleway;
            font-size: 16px;

            @media screen and (max-width: 520px) {
                font-size: 14px;
            }
        }

        .input_container {
            display: flex;
            align-items: center;

            &:not(:last-child) {
                margin-bottom: 5px;
            }
            
            input {
                width: 100%;
                max-width: 70px;
                margin-right: 10px;
            }

            span {
                color: $white;
            }
        }
    }
}

.analytics_container {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 1024px) {
        flex-direction: column;
    }

    .column {
        flex: 1;  
        padding: 10px; 
        //border: 1px solid #ccc; 

        .plot_header {
            font-size: 16px;
            font-weight: 600;
            color: #fff;
            padding-top: 10px;
            padding-bottom: 10px;
        }

        .plot_block {
            background-color: '#37335C';
            padding-bottom: 20px;
        }
        
        .statistics_section {
            display: flex;
            flex-wrap: wrap; 
            justify-content: space-around; 
            gap: 20px; 

            .statistics_block {
                flex: 1 1 calc(50% - 20px); 
                min-width: 200px; 
                height: 150px;
                margin-bottom: 20px; 
    
                .statistics_header {
                    font-size: 16px;
                    color: #fff;
                    font-weight: 600;
                    height: 20%;
                }
                
                .statistics_value {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 80%;
                    border: 2px solid $main_green; 
                    border-radius: 10px;
                    background-color: #37335C;
    
                    div {
                        color: #fff;
                        font-size: 30px;
                        font-weight: 700;
                    }
                }
            }
        }

        .statistics_column_section {
            display: flex;
            flex-wrap: wrap; 
            flex-direction: column;
            gap: 20px; 

            .statistics_block {
                //flex: 1 1 calc(50% - 20px); 
                padding-left: 20px;
                padding-right: 20px;
                min-width: 200px; 
                height: 100px;
                margin-bottom: 20px; 
    
                .statistics_header {
                    font-size: 16px;
                    color: #fff;
                    font-weight: 600;
                    height: 20%;
                    padding-top: 10px;
                    padding-bottom: 10px;
                }
                
                .statistics_value {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 80%;
                    border: 2px solid $main_green; 
                    border-radius: 10px;
                    background-color: #37335C;
    
                    div {
                        color: #fff;
                        font-size: 30px;
                        font-weight: 700;
                    }
                }
            }
        }
    }
}

.settings_wrapper {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    @media screen and (max-width: 520px) {
        padding-bottom: 50px;
    }

}

.settings_button {
    padding: 10px 24px;
    border-radius: 3px;
    background-color: $main_green;
    margin-right: 20px;
    border: none;

    @media screen and (max-width: 590px) {
        margin-right: 0;
    }

    @media screen and (max-width: 520px) {
        position: fixed;
        right: 16px;
        bottom: 16px;
    }

    color: $main_blue;
    font-size: 16px;
    font-weight: 700;

    transition: background-color .2s;

    &:hover {
        cursor: pointer;
        background-color: $main_green_hover;
    }
}