@import './colors.scss';

.root {
    box-sizing: 'border-box';
    padding: 20px;
    margin: 0 0 10px;
    border-radius: 5px;
    background: linear-gradient(180deg, #050033 93.23%, rgba(5, 0, 51, 0.50) 100%, rgba(5, 0, 51, 0.50) 100%, rgba(5, 0, 51, 0.50) 100%, rgba(5, 0, 51, 0.50) 100%, rgba(5, 0, 51, 0.50) 100%, rgba(5, 0, 51, 0.50) 100%);

    @media screen and (max-width: 620px) {
        padding: 16px;
    }

    &.mybets_root {
        position: relative;
        padding-top: 0;
        //padding-bottom: 55px;

        &.edit {
            z-index: 1000;
        }

        .header {
            padding-bottom: 0;
        }
    }
    
    p, span, input {
        @media screen and (max-width: 920px) {
            font-size: 14px;
        }

        @media screen and (max-width: 760px) {
            font-size: 11px;
        }
    }

    .header {
        color: $white;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 15px;
        border-bottom: 1px solid $main_green;
        margin-bottom: 10px;
    }

    .info {
        display: flex;
        justify-content: end;
        align-items: center;
    }

    .icon {
        display: flex;
        align-items: center;
        
        span {
            font-weight: 600;

            &::first-letter {
                text-transform: uppercase;
            }

            &:first-child {
                color: $main_green;
            }
        }

        .delimiter {
            margin: 0 15px;
            color: rgba(255, 255, 255, 0.30);
            font-size: 20px;
        }
    }

    .bet_meta {
        display: flex;
        align-items: center;
        
        span {
            font-weight: 600;

            &::first-letter {
                text-transform: uppercase;
            }

            &:first-child {
                color: $main_green;
            }
        }

        .delimiter {
            margin: 0 15px;
            color: rgba(255, 255, 255, 0.30);
            font-size: 20px;
        }
    }

    .info_text {
        font-family: 'Raleway';
    }

    .edit_button {
        color: $main_green;
        margin-left: 20px;
        
        &:hover {
            cursor: pointer;
            border-bottom: 1px solid $main_green;
        }
    }
    
    .more_button {
        color: $white;
        margin: 5px auto 0;
    }

    .show_button {
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: .5;
        font-weight: 600;
        transition: opacity .2s;

        &:hover {
            opacity: .8;
            cursor: pointer;
        }

        img {
            display: block;
            width: 15px;
            height: 15px;
            object-fit: contain;
            object-position: center;
            margin-left: 10px;
        }
    }

    .items_delimiter {
        height: 1px;
        width: 100%;
        background: rgba(255, 255, 255, 0.30);
        margin: 10px 0;
    }

    .place_info,
    .status_info {
        p {
            color: $main_blue;
            font-size: 16px;
            font-weight: 600;
            margin: 0;

            @media screen and (max-width: 760px) {
                font-size: 11px;
            }
        }
    }

    .place_info_pending {
        background-color: #827F99 !important;
    }

    .place_info_won {
        background-color: $main_green !important;
    }

    .place_info_lost {
        background-color: #FF5252 !important;
    }

    .status_info_pending {
        p {
            color: #827F99;
        }
    }

    .status_info_won {
        p {
            color: $main_green;
        }
    }

    .status_info_lost {
        p {
            color: #FF5252;
        }
    }

    .place_info {
        padding: 18px 30px;
        background-color: $main_green;
        margin-left: 30px;
    }

    .status_info {
        margin-left: 20px;
    }

    .close_wrapper {
        margin-left: 20px;
        transition: opacity .2s;

        &:hover {
            cursor: pointer;
            opacity: .8;
        }
    }
}

.modular_wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;

    display: flex;
    align-items: center;
    justify-content: center;

    .green {
        color: $main_green;
    }

    .red {
        color: #FF5252;
    }

    .red_border {
        border-color: #FF5252 !important;
    }

    .modular_item {
        width: 100%;
        max-width: 530px;
        border-radius: 5px;
        border: 2px solid $main_green;
        background: $main_blue;
        padding: 20px;
        box-sizing: border-box;

        .top {
            padding-top: 20px;
            display: flex;
            flex-direction: column;

            .name_wrapper {
                display: flex;
                flex-direction: row;

                .icon {
                    width: 20px;
                    height: 20px;
                    padding-right: 10px;
                    padding-left: 10px;
                }

                .name {
                    color: $white;
                    font-size: 16px;
                    font-weight: 700;

                }

                .subname {
                    color: $white;
                    font-size: 12px;
                    font-weight: 400;
                }

                .share_name {
                    color: $white;
                    font-size: 18px;
                    font-weight: 700;

                }

                .share_icons {
                    display: flex;
                    gap: 10px;
                }
            }

            .info {
                color: $white;
                font-size: 16px;
                font-weight: 400;
            }
        }

        .action_wrapper {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            padding-top: 15px;
            padding-bottom: 5px;
            margin-bottom: 5px;

            button {
                padding: 10px 20px;
                margin: 0 5px;
                border-radius: 3px;
                border: none;
                transition: opacity .2s;

                &:hover {
                    cursor: pointer;
                    opacity: .8;
                }

                span {
                    color: $main_blue;
                    font-family: Raleway;
                    font-size: 16px;
                    font-weight: 700;
                }
            }

            .red_button {
                background-color: #FF5252;
                span {
                    color: #fff;
                }
            }
        }

    }
}

.edit_bg {
    display: none;
    
    &.active {
        display: block;
        position: fixed;
        left: 0;
        top: 70px;
        width: 100vw;
        height: calc(100vh - 70px);
        background-color: white;
        opacity: .5;
        z-index: 100;
    }
}

@media screen and (max-width: 620px) {
    .mybets_root {
        .header {
            flex-wrap: wrap;

            .icon {
                
                flex-wrap: wrap;
                .status_info {
                    width: 100%;
                    margin-top: 10px;
                    p {
                        font-size: 14px !important;
                    }
                    
                }
            }
        }
    
        .info {
            width: 100%; 
            margin-top: 10px;
            margin-bottom: 10px;
            justify-content: flex-end; 
        }
    
        .place_info, .status_info, .edit_button, .close_wrapper {
            text-align: center; 
        }
    
        .edit_button, .close_wrapper {
            justify-self: end;
        }
    }
}

.screenshot_container {
    padding: 100px 20px 20px 20px;
    background: linear-gradient(130.5deg, rgba(145, 230, 23, 0.5) 0%, rgba(5, 0, 51, 0.5) 99.45%);
    box-sizing: border-box;
    position: relative;

    .screenshot_logo {
        position: absolute;
        top: 10px;
        right: 20px;
    }
}
