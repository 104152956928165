@import './colors.scss';

.hidden {
    .filter_header {
        h2 {
            display: none;
        }
    }

    .filter_container,
    .save_wrapper,
    .bottom_wrapper,
    .saved_filters,
    .free_wrapper,
    .datetime_section,
    .save_button,
    .saved_filters_wrapper {
        display: none !important;
    }

    .hide_button {
        transform: rotate(180deg) !important;
    }
}

.filter {
    background-color: $main_blue;
    border-radius: 5px;
    padding: 20px;
    position: relative;

    &.free > div:not(.free_wrapper) {
        opacity: .5;
    }

    .value_wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
            display: inline-block;
            color: $white;
            font-size: 16px;
            font-weight: 600;
        }
    }

    .input_block {
        width: 100%;
        position: relative;
        margin-top: 10px;
        margin-bottom: 10px;

        input {
            width: 100%;
            box-sizing: border-box;
            height: 45px;
            padding: 13px 40px 13px 10px;
            font-size: 16px;
            font-family: 'Raleway';
            outline:none;
        }

        img {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
        }

        .clear_icon {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
        }

        .autocomplete_list {
            position: absolute;
            //background-color: white;
            background: linear-gradient(180deg, #050033 31.82%, rgba(105, 102, 133, 0.799938) 171.21%);

            //border: 1px solid #ccc;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            width: 100%;
            z-index: 1000;
            padding: 0;      
            margin: 0;   
            padding-left: 10px;    
            list-style: none;
            max-height: 300px;
            overflow-y: auto;
            box-sizing: border-box;
          
            li {
              padding: 10px;
              cursor: pointer;
              color: #000;
          
              &:hover {
                background-color: #f0f0f0;
              }
            }
          }
    }

    .free_wrapper {
        padding: 15px 10px;
        border: 2px solid $main_green;
        border-radius: 5px;
        background-color: $main_blue;
        z-index: 10;
        width: 100%;

        @media screen and (max-height: 580px) {
            bottom: 10px;
        }

        @media screen and (min-height: 511px) {
            position: fixed;
            bottom: 78px;
            left: 32px;
            padding: 15px;
            width: calc(24.3% - 84px);
        }

        @media screen and (max-height: 510px) and (min-width: 1081px) {
            margin-left: -10px;
        }
        
        @media screen and (max-width: 1080px) {
            position: fixed;
            bottom: 78px;
            width: calc(100% - 70px);
            left: 16px;
            padding: 15px;
        }

        p {
            color: $white;
            text-align: center;
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 20px;

            span {
                color: $main_green;
                text-decoration: underline;
            }
        }

        button {
            padding: 10px;
            background-color: $main_green;
            color: $main_blue;
            font-weight: 700;
            font-size: 16px;
            border: none;
            border-radius: 5px;
            width: 100%;
            font-family: Raleway;
            transition: background-color .2s;

            &:hover {
                cursor: pointer;
                background-color: $main_green_hover;
            }
        }
    }

    .filter_wrapper {
        padding: 15px 10px;
        border: 2px solid $main_green;
        border-radius: 5px;
        background-color: $main_blue;
        z-index: 10;
        width: 100%;
        box-sizing: border-box;
        margin-bottom: 10px;

        p {
            color: $white;
            text-align: center;
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 20px;

            span {
                color: $main_green;
                text-decoration: underline;
            }
        }

        button {
            padding: 10px;
            background-color: $main_green;
            color: $main_blue;
            font-weight: 700;
            font-size: 16px;
            border: none;
            border-radius: 5px;
            width: 100%;
            font-family: Raleway;
            transition: background-color .2s;

            &:hover {
                cursor: pointer;
                background-color: $main_green_hover;
            }
        }
    }

    &::-webkit-scrollbar {
        width: 5px;
        height: 6px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 2px;
        border: 0.4px solid $white;
        background: $main_blue;
    }

    &::-webkit-scrollbar-thumb{
        border-radius: 2px;
        background: rgba(0,0,0,0.4);
    }

    &::-webkit-scrollbar-thumb:hover{
        background: rgba(0,0,0,0.4);
    }
    
    &::-webkit-scrollbar-thumb:active{
        background: rgba(0,0,0,.9);
    }

    @media screen and (max-width: 620px) {
        padding: 16px;
    }

    .filter_header_name {
        color: $main_green;
    }

    .filter_button {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(0, -50%);
        display: flex;
        align-items: center;
        transition: opacity .2s;

        &:hover {
            opacity: .8;
            cursor: pointer;
        }

        &.close_button,
        &.hide_button {
            right: 0;
            left: unset;
        }

        &.hide_button {
            transition: transform .2s;

            @media screen and (max-width: 1080px) {
                display: none;
            }
        }

        &.close_button {
            @media screen and (min-width: 1081px) {
                display: none;
            }
        }

        .switch_filter {
            margin-right: 5px;
        }

        .active {
            transition: transform .2s;
            transform: rotate(180deg);
        }
    }

    .filter_header {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    h2 {
        color: $white;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .filter_container{
        padding: 20px 0 0;
        overflow: hidden;

        @media screen and (max-width: 620px) {
            padding: 16px 0 0;
        }

        .item {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -10px;
            padding-bottom: 5px;
            border-bottom: 1px solid $border_color;

            label {
                margin: 0 10px;

                span:first-child {
                    padding: 10px 0;
                    margin-right: 6px;
                }

                span:last-child {
                    font-family: 'Raleway', sans-serif;

                    &::first-letter {
                        text-transform: uppercase;
                    }
                }
            }
        }

        .line_filter {
            margin: 0;
            padding: 0 10px;
        }
    }

    .non_clickable_div {
        pointer-events: none; /* Disables clicks and hover on divs */
    }

    .filter_slider_container,
    .filter_select_container {
        .item {
            padding-bottom: 15px;
        }
    }

    .filter_bookmakers_container {
        & > div {
            label {
                display: block;
                width: 100%;
            }
        }
    }

    .filter_bookmakers_container,
    .filter_sports_container {
        .item {
            max-height: 0;
            overflow: hidden;

            &:not(.scroll_item).active {
                max-height: max-content;
            }
        }

        .scroll_item {
            &.active {
                max-height: 40vh;
                overflow-y: auto;

                &::-webkit-scrollbar {
                    width: 5px;
                    height: 6px;
                }
            
                &::-webkit-scrollbar-track {
                    border-radius: 2px;
                    border: 0.4px solid $white;
                    background: $main_blue;
                }
            
                &::-webkit-scrollbar-thumb{
                    border-radius: 2px;
                    background: rgba(0,0,0,0.4);
                }
            
                &::-webkit-scrollbar-thumb:hover{
                    background: rgba(0,0,0,0.4);
                }
                
                &::-webkit-scrollbar-thumb:active{
                    background: rgba(0,0,0,.9);
                }
            }
        }
    }

    .filter_select_container {
        .item {
            margin: 0 5px;
            display: flex;
            align-items: center;

            span {
                margin-left: 10px;
            }

            legend {
                span {
                    margin: 0;
                }
            }
        }
    }

    .filter_item_header {
        display: flex;
        color: $white;
        font-weight: 700;
        margin-bottom: 15px;
    }

    .filter_item_header_container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
        transition: opacity .2s;

        @media screen and (max-width: 620px) {
            margin-bottom: 8px;
        }

        .filter_item_header {
            margin-bottom: 0;
        }

        img {
            width: 15px;
            height: 15px;
            object-fit: contain;
            object-position: center;
            transition: transform .2s;

            &.active {
                transform: rotate(180deg);
            }
        }

        &:hover {
            cursor: pointer;
            opacity: .8;
        }
    }

    .save_wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 15px 0;
        width: 100%;

        input {
            width: inherit;
            height: 20px;
            padding: 5px 10px;
            border-radius: 3px;
            border: 1px solid $white;
            background: $main_blue;
            color: $white;
            font-family: 'Raleway', sans-serif;
            font-size: 16px;

            &::placeholder {
                color: $white;
            }
        }

        label {
            span {
                font-family: Raleway;
                font-weight: 700;
            }
        }

        .input_wrapper {
            min-width: max-content;
        }
    }

    .bottom_wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width: 1200px) {
            display: block;
        }

        .clear_container {
            display: flex;
            align-items: center;
            transition: opacity .2s;

            @media screen and (max-width: 1200px) {
                margin-bottom: 15px;
            }

            &:hover {
                cursor: pointer;
                opacity: .8;
            }

            span {
                margin-left: 10px;
                color: $white;
                font-weight: 700;
                text-decoration-line: underline;
            }

            img {
                width: 31px;
                height: 22px;
                object-fit: contain;
                object-position: center;
                margin-top: 3px;
            }
        }
    }

    .search_button {
        background: $main_green;
        padding: 10px 20px;
        color: $main_blue;
        font-weight: 700;
        font-family: 'Raleway', sans-serif;
        line-height: normal;
        transition: background-color .2s;

        &:not(.disable) {
            &:hover {
                cursor: pointer;
                background-color: $main_green_hover;
            }
        }

        @media screen and (max-width: 1200px) {
            width: 100%;
        }

        @media screen and (max-width: 620px) {
            padding: 10px 16px;
        }
    }

    .save_button {
        margin-bottom: 15px;
        background-color: transparent;
        border: 1px solid $white;
        color: $white;
        width: 100%;
        transition: background-color .2s color .2s;

        &:hover {
            background-color: $main_green;
            color: $main_blue;
        }
    }

    .info_text {
        font-size: 9px;
        color: $main_green;
    }
}

.saved_filters_wrapper {
    margin-top: 20px;
    border-radius: 5px;
    border: 2px solid $main_green;
    padding: 20px;

    @media screen and (max-width: 620px) {
        padding: 16px; 
        margin-top: 16px; 
    }

    .saved_header {
        display: block;
        text-align: center;
        font-weight: 600;
        margin-bottom: 10px;
    }

    .saved_item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $border_color;

        &:hover {
            cursor: pointer;

            .filter_name,
            .item_container span {
                color: $main_green;
            }
        }
    }

    .filter_name {
        color: $white;
        display: block;
        padding: 10px 0;
        width: 100%;
        transition: color .2s;
    }

    .item_container {
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            color: $white;
            font-size: 12px;
            max-width: 80px;
            display: block;
            transition: color .2s;
        }

        img {
            width: 10px;
            height: 10px;
            object-fit: contain;
            object-position: center;
            margin-left: 15px;
        }
    }
}

.add_new_filter {
    padding: 10px;
    background-color: $main_green;
    color: $main_blue;
    border-radius: 5px;
    margin-top: 15px;
    width: 100%;
    font-weight: 600;
    font-size: 14px;
    transition: background-color .2s;

    &:hover {
        cursor: pointer;
        background-color: $main_green_hover;
    }
}

.datetime_section {
    padding-top: 20px;
    padding-bottom: 20px;

    .datetime_item {
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

.close_red_button {
    padding: 0;
    margin: 0;
    width: 19px !important;
    height: 19px !important;
    background-color: transparent;
    border: none;

    transition: opacity .2s;
    padding-left: 5px;

    &:hover {
        opacity: .8;
        cursor: pointer;
    }

    img {
        width: 19px !important;
        height: 19px !important;
        object-fit: contain;
        object-position: center;
        margin-right: 10px;

        @media screen and (max-width: 920px) {
            width: 14px;
            height: 14px;
            margin-right: 5px;
        }
    }
}


.modular_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;

    display: flex;
    align-items: center;
    justify-content: center;

    .green {
        color: $main_green;
    }

    .red {
        color: #FF5252;
    }

    .red_border {
        border-color: #FF5252 !important;
    }

    .modular_item {
        width: 100%;
        max-width: 530px;
        border-radius: 5px;
        border: 2px solid $main_green;
        background: $main_blue;
        padding: 20px;
        box-sizing: border-box;

        .top {
            padding-top: 20px;
            display: flex;
            flex-direction: column;

            .name_wrapper {
                display: flex;
                flex-direction: row;

                .icon {
                    width: 20px;
                    height: 20px;
                    padding-right: 10px;
                    padding-left: 10px;
                }

                .subname {
                    color: $white;
                    font-size: 16px;
                    font-weight: 600;
                }
            }

            .checkbox_area {
                padding-top: 20px;
                padding-bottom: 20px;
            }

            .info {
                color: $white;
                font-size: 16px;
                font-weight: 400;
            }
        }

        .action_wrapper {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            padding-bottom: 5px;
            margin-bottom: 5px;

            button {
                padding: 10px 30px;
                border-radius: 3px;
                background-color: $main_green;
                border: none;
                transition: opacity .2s;

                &:hover {
                    cursor: pointer;
                    opacity: .8;
                }

                span {
                    color: $main_blue;
                    font-family: Raleway;
                    font-size: 16px;
                    font-weight: 700;
                }
            }
        }

    }

    
    
}