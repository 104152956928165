@import './colors.scss';

.notifications_page {
    position: relative;
    width: 100%;
    background-color: $main_blue;
    margin: 20px 20px 0;
    padding: 20px;
    border-radius: 5px;

    .notifications_header {
        color: $white;
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 10px;
        display: inline-block;
    }

    .warning_wrapper {
        display: flex;
        align-items: center;

        img {
            margin-right: 10px;
        }

        span {
            color: $main_green;
            font-size: 16px;
            font-weight: 400;
        }
    }

    .setting_Wrapper {
        width: 100%;
        color: $white;
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .deselect {
            font-size: 16px;
            font-weight: 600;
            text-decoration-line: underline;
            margin-bottom: 2px;
        }

        .remove {
            margin-left: 20px;
        }

        label {
            span {
                &:last-child {
                    font-family: Raleway;
                    font-size: 16px;
                    font-weight: 600;
                }
            }
        }
    }

    .dropdown_button {
        margin-left: 20px;

        button {
            display: flex;
            align-items: center;

            span {
                margin: 0 10px;
            }

            img {
                transition: transform .2s;
            }
        }
    }

    .notifications_table {
        width: 100%;
        margin-top: 0;
    
        table {
            border-collapse: separate;
            border-spacing: 0 20px;
        }
    
        & > div {
            background-color: transparent;
        }
    
        th, td {
            font-family: Raleway;
            border-bottom: none;
        }
    
        thead {
            th {
                color: $white;
                padding: 3px 3px 0;
            }
        }
    
        tbody {
            tr {
                transition: opacity .2s;
    
                &:hover {
                    cursor: pointer;
                    opacity: .9;
                }
    
                td {
                    padding: 3px;
                    color: $white;
                    background: rgba(255, 255, 255, 0.20);
    
                    &:first-child {
                        border-top-left-radius: 3px;
                        border-bottom-left-radius: 3px;
                    }
                    
                    &:nth-child(7) {
                        border-top-right-radius: 3px;
                        border-bottom-right-radius: 3px;
                    }
    
                    &:last-child {
                        background: none;
                        padding-left: 10px;
                        padding-right: 10px;
                    }
                }
            }
        }
    }
}