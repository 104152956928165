@import './colors.scss';

.root {
    height: 100%;

    .mybets_list {
        height: calc(100% - 151px);
    }
}

.dropdown_button {
    
    button {
        display: flex;
        align-items: center;
    }

    span {
        display: inline-block;
        margin: 0 10px;
        color: $main_blue;

        @media screen and (max-width: 580px) {
            font-size: 14px;
        }
    }
}

.control_panel {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 20px;
    box-sizing: border-box;

    @media screen and (max-width: 620px) {
        padding: 0 0 8px;
        margin-top: -16px;
    }

    .header_text {
        color: $main_blue;
        font-size: 20px;
        font-weight: 800;

        @media screen and (max-width: 580px) {
            font-size: 14px;
            margin-left: 15px;
        }
    }
}

.modular_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;

    display: flex;
    align-items: center;
    justify-content: center;

    .green {
        color: $main_green;
    }

    .red {
        color: #FF5252;
    }

    .red_border {
        border-color: #FF5252 !important;
    }

    .modular_item {
        width: 100%;
        max-width: 530px;
        max-height: 100vh;
        overflow-y: auto;
        border-radius: 5px;
        border: 2px solid $main_green;
        background: $main_blue;
        padding: 20px;
        box-sizing: border-box;
        position: relative;

        .form_wrapper {
            color: #fff;

            .error {
                color: #FF5252;
            }

            .select {
                background-color: $main_blue;
                padding: 5px;
                border: 1px solid white;
                border-radius: 5px;
                color: white;
                cursor: pointer;

                option {
                    cursor: pointer;
                }
            }

            .label {
                color: white;
                margin-right: 10px;
            }

            .datetime_item {
                padding-top: 5px;
                padding-bottom: 10px;
            }

            .input_block {
                width: 100%;
                position: relative;
                margin-top: 10px;
                margin-bottom: 10px;
        
                input {
                    width: 100%;
                    box-sizing: border-box;
                    height: 45px;
                    padding: 13px 40px 13px 10px;
                    font-size: 16px;
                    font-family: 'Raleway';
                    outline:none;
                }
        
                img {
                    position: absolute;
                    right: 10px;
                    top: 50%;
                    transform: translateY(-50%);
                }
        
                .clear_icon {
                    position: absolute;
                    right: 10px;
                    top: 50%;
                    transform: translateY(-50%);
                    cursor: pointer;
                }
        
                .autocomplete_list {
                    position: absolute;
                    //background-color: white;
                    background: linear-gradient(180deg, #050033 31.82%, rgba(105, 102, 133, 0.799938) 171.21%);
        
                    //border: 1px solid #ccc;
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                    width: 100%;
                    z-index: 1000;
                    padding: 0;      
                    margin: 0;   
                    padding-left: 10px;    
                    list-style: none;
                    max-height: 300px;
                    overflow-y: auto;
                    box-sizing: border-box;
                    color: #fff;
                  
                    li {
                      padding: 10px;
                      cursor: pointer;
                      color: #000;
                  
                      &:hover {
                        background-color: #f0f0f0;
                      }
                    }
                  }
            }

            .selected_bookmaker {
                display: flex;
                align-items: center;
                padding-top: 10px;
                padding-bottom: 10px;
                img {
                    width: 40px;
                    height: 40px;
                    border-radius: 5px;
                }
                span:first-child {
                    padding-right: 10px;
                }
                span:last-child {
                    padding-left: 10px;
                }
            }

            button {
                padding: 10px 20px;
                border-radius: 3px;
                background-color: $main_green;
                border: none;
                transition: opacity .2s;

                &:hover {
                    cursor: pointer;
                    opacity: .8;
                }

                span {
                    color: $main_blue;
                    font-family: Raleway;
                    font-size: 16px;
                    font-weight: 700;
                }
            }
        }

        .top {
            display: flex;
            justify-content: space-between;

            .name_wrapper {
                display: flex;
                flex-direction: column;

                .name {
                    font-size: 20px;
                    font-weight: 700;
                    display: inline-block;
                    margin-bottom: 10px;
                }

                .subname {
                    color: $white;
                    font-size: 16px;
                    font-weight: 600;
                }
            }

            .right_part {
                display: flex;
            }

            .balance_wrapper {
                max-width: 142px;
                margin: 0 20px;
                
                & > span {
                    color: $white;
                    font-size: 16px;
                    font-weight: 600;
                    margin-bottom: 10px;
                    display: inline-block;
                }

                .value {
                    border-radius: 3px;
                    border: 2px solid $main_green;
                    padding: 13px 10px;
                    display: flex;
                    align-items: center;

                    span,
                    input {
                        color: $white;
                        font-family: Raleway;
                        font-size: 16px;
                        font-weight: 600;
                    }

                    input {
                        width: 100%;
                        border: none;
                        background-color: $main_blue;
                        margin: 0 10px;
                    }
                }                
            }

            .close {
                transition: opacity .2s;

                &:hover {
                    cursor: pointer;
                    opacity: .8;
                }
            }
        }

        .action_wrapper_v2 {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            padding-bottom: 20px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.50);
            margin-bottom: 10px;

            .cancel_button {
                padding: 10px 20px;
                margin: 0 5px;
                border-radius: 3px;
                border: none;
                transition: opacity .2s;
                font-weight: 500;

                &:hover {
                    cursor: pointer;
                    opacity: .8;
                }

                span {
                    color: $main_blue;
                    font-family: Raleway;
                    font-size: 16px;
                    font-weight: 700;
                }
            }

            .confirm_button {
                padding: 10px 20px;
                margin: 0 5px;
                border-radius: 3px;
                border: none;
                transition: opacity .2s;
                background-color: $main_green;

                &:hover {
                    cursor: pointer;
                    opacity: .8;
                }

                span {
                    color: $main_blue;
                    font-family: Raleway;
                    font-size: 16px;
                    font-weight: 700;
                }
            }
        }

        .action_wrapper {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            padding-bottom: 20px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.50);
            margin-bottom: 10px;

            button {
                padding: 10px 50px;
                border-radius: 3px;
                background-color: $main_green;
                border: none;
                transition: opacity .2s;

                &:hover {
                    cursor: pointer;
                    opacity: .8;
                }

                span {
                    color: $main_blue;
                    font-family: Raleway;
                    font-size: 16px;
                    font-weight: 700;
                }
            }

            .cancel_button {
                padding: 10px 20px;
                margin: 0 5px;
                border-radius: 3px;
                border: none;
                transition: opacity .2s;
                font-weight: 500;

                &:hover {
                    cursor: pointer;
                    opacity: .8;
                }

                span {
                    color: $main_blue;
                    font-family: Raleway;
                    font-size: 16px;
                    font-weight: 700;
                }
            }

            .confirm_button {
                padding: 10px 20px;
                margin: 0 5px;
                border-radius: 3px;
                border: none;
                transition: opacity .2s;
                background-color: $main_green;

                &:hover {
                    cursor: pointer;
                    opacity: .8;
                }

                span {
                    color: $main_blue;
                    font-family: Raleway;
                    font-size: 16px;
                    font-weight: 700;
                }
            }

            .input_wrapper {
                margin-right: 20px;
               
                & > span {
                    color: $white;
                    font-size: 16px;
                    font-weight: 600;
                    margin-bottom: 10px;
                    display: inline-block;
                }

                .value {
                    border-radius: 3px;
                    border: 2px solid $main_green;
                    padding: 13px 10px;
                    display: flex;
                    align-items: center;

                    span,
                    input {
                        color: $white;
                        font-family: Raleway;
                        font-size: 16px;
                        font-weight: 600;
                    }

                    input {
                        width: 100%;
                        border: none;
                        background-color: $main_blue;
                        margin: 0 10px;
                    }
                } 
                
                &.white {
                    .value {
                        background-color: $white;
                        border-color: $white;

                        span,
                        input {
                            color: $main_blue;
                        }

                        input {
                            background-color: $white;
                        }
                    }
                }
            }
        }

        .table_wrapper {
            .table_header {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .name {
                color: $white;
                font-size: 16px;
                font-weight: 700;
            }

            .dropdown_button {
                span {
                    color: $white;
                    font-size: 12px;
                    font-weight: 600;
                }

                img {
                    width: 14px;
                    height: 14px;
                    object-fit: contain;
                    object-position: center;
                }
            }
        }

        .modular_table {
            width: 100%;
            margin-top: 0;
        
            table {
                border-collapse: separate;
                border-spacing: 0 10px;
            }
        
            & > div {
                background-color: transparent;
            }
        
            th, td {
                font-family: Raleway;
                border-bottom: none;
            }
        
            thead {
                th {
                    color: $white;
                    padding: 3px 3px 0;
                }
            }
        
            tbody {

                tr {
                    transition: opacity .2s;
        
                    &:hover {
                        cursor: pointer;
                        opacity: .9;
                    }
        
                    td {
                        padding: 7px 20px;
                        color: $white;
                        background: rgba(255, 255, 255, 0.20);
        
                        &:first-child {
                            border-top-left-radius: 3px;
                            border-bottom-left-radius: 3px;
                        }
                        
                        &:last-child {
                            border-top-right-radius: 3px;
                            border-bottom-right-radius: 3px;
                        }
                    }
                }
            }
        }

        .value_list {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: calc(100% - 54px);
            margin: 40px 27px 30px;

            button {
                width: calc(33.333% - 10px);
                padding: 10px;
                border-radius: 20px;
                background: linear-gradient(90deg, $main_green 0%, rgba(145, 230, 23, 0.50) 100%);
                display: flex;
                justify-content: space-between;
                align-items: center;
                border: none;
                transition: opacity .2s;

                &:hover {
                    cursor: pointer;
                    opacity: .8;
                }

                span {
                    color: $main_blue;
                    font-family: Raleway;
                    font-size: 16px;
                    font-weight: 600;

                    &:first-child {
                        display: inline-block;
                        margin-right: 10px;
                    }
                }
            }
        }

        @media screen and (max-width: 760px) {
            span, input {
                font-size: 11px !important;
            }
            
            .action_wrapper {
                flex-direction: column;
                align-items: center;
            }
            
            button {
                margin-top: 10px;
            }
        }
    }
}

.mybets_statistics {
    display: flex;
    align-items: flex-end;
    margin-bottom: 20px;
    
    .add_bet_container {
        display: flex;
        height: 97px;
        align-items: center;
        padding-left: 20px;
        .add_bet_button {
            background: $main_green;
            padding: 10px 20px;
            color: $main_blue;
            font-weight: 700;
            font-family: 'Raleway', sans-serif;
            line-height: normal;
            transition: background-color .2s;
            border-radius: 5px;
    
            &:not(.disable) {
                &:hover {
                    cursor: pointer;
                    background-color: $main_green_hover;
                }
            }
        }
    }
    
    .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &:not(:first-child) {
            margin-left: 20px;
        }
    }

    .item_action {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 97px;
        margin-left: 10px;

        button {
            border-radius: 3px;
            border: 2px solid $main_green;
            background: $main_blue;
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
                cursor: pointer;
            }

            span {
                color: $main_green;
                font-family: Raleway;
                font-size: 20px;
                font-weight: 600;
            }

            &.down {
                border: 1px solid #FF5252;

                span {
                    color: #FF5252;
                    font-family: Raleway;
                    font-weight: 400;
                }
            }
        }
    }

    .name {
        color: $main_blue;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 15px;
    }

    .value {
        border-radius: 5px;
        border: 2px solid $main_green;
        background: $main_blue;
        padding: 23px 60px;
        position: relative;

        span {
            color: $white;
            font-size: 40px;
            font-weight: 600;
        }

        .plot_icon {
            position: absolute;
            top: 10px;
            right: 10px;
            cursor: pointer;
            width: 20px;

            @media screen and (max-width: 760px) {
                width: 10px;
            }
        }
    }

    .value_selected {
        background: #383838 !important;

        span {
            color: $main_green;
        }

        div {
            color: $main_green;
        }
    }

    .price_wrapper {
        color: $white;
        font-size: 20px;
        font-weight: 600;

        .price {
            margin: 0 10px;
        }
    }

    @media screen and (max-width: 760px) {
        flex-wrap: wrap;

        div {
            font-size: 11px !important;
        }

        span {
            font-size: 15px !important;
        }

        .value {
            padding: 15px 30px;
        }

        .item {
            margin-top: 10px;
            margin-left: 20px;
        }

        .item_action {
            height: 70px
        }
        
    }
}

.popup {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.500);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;

    .module_wrapper {
        
        width: 80%;
        left: 10%;
        top: 25%;
        max-width: 426px;
        background-color: #050033;
        border: 1px solid #FF5252;
        border-radius: 5px;
        padding: 32px 20px 20px;

        .content {
            margin-bottom: 20px;
            position: relative;

            span {
                display: inline-block;
                //margin-left: 40px;
                font-weight: 600;
                font-size: 16px;
                color: $white;

                &.red {
                    color: #FF5252;
                    margin-left: 0;
                }
            }
        }

        .action_block {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            button {
                padding: 10px 16px;
                border: none;
                border-radius: 3px;
                width: fit-content;
                font-weight: 500;
                font-family: 'Raleway';
                transition: opacity .2s;

                &:hover {
                    cursor: pointer;
                    opacity: .8;
                }

                &.left {
                    background-color: $white;
                }

                &.right {
                    background-color: #FF5252;
                    color: $white;
                    margin-left: 20px;
                }
            }
        }
    }
}

.modular_wrapper_profit {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;

    display: flex;
    align-items: center;
    justify-content: center;

    .green {
        color: $main_green;
    }

    .red {
        color: #FF5252;
    }

    .red_border {
        border-color: #FF5252 !important;
    }

    .modular_item {
        width: 100%;
        max-width: 630px;
        border-radius: 5px;
        border: 2px solid $main_green;
        background: #383838;
        padding: 20px;
        box-sizing: border-box;
        position: relative;

        .toggles {
            margin-top: 10px;
            margin-bottom: 10px;
            padding: 0 20px;
            display: flex;
            width: 100%;
            justify-content: space-between;
            box-sizing: border-box;

            .switch_toggle {
                display: flex;
                flex-direction: row;
                width: auto;
                align-items: center; /* Для выравнивания по центру по вертикали */

                span {
                    font-size: 12px;
                    max-width: 50px;
                    color: #fff;
                }
                
                span:first-child {
                    font-size: 12px;
                    max-width: 50px;
                    color: #fff;
                    padding-right: 2px; /* Добавляем отступ справа для первого span */
                }
                
                span:last-child {
                    font-size: 12px;
                    max-width: 50px;
                    color: #fff;
                    padding-left: 8px; /* Добавляем отступ слева для второго span */
                }
            }
        }

        .top {
            padding-top: 20px;
            display: flex;
            flex-direction: column;

            .name_wrapper {
                display: flex;
                flex-direction: row;

                .icon {
                    width: 20px;
                    height: 20px;
                    padding-right: 10px;
                    padding-left: 10px;
                }

                .subname {
                    color: $white;
                    font-size: 16px;
                    font-weight: 600;
                }
            }

            .checkbox_area {
                padding-top: 20px;
                padding-bottom: 20px;
            }

            .info {
                color: $white;
                font-size: 16px;
                font-weight: 400;
            }
        }

        .action_wrapper {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            padding-bottom: 5px;
            margin-bottom: 5px;

            button {
                padding: 10px 30px;
                border-radius: 3px;
                background-color: $main_green;
                border: none;
                transition: opacity .2s;

                &:hover {
                    cursor: pointer;
                    opacity: .8;
                }

                span {
                    color: $main_blue;
                    font-family: Raleway;
                    font-size: 16px;
                    font-weight: 700;
                }
            }
        }

        .plot_block {
            display: flex;
            width: 100%;
            margin: 0;
            padding: 0 20px;
            box-sizing: border-box;

            min-height: 300px;

            @media screen and (max-width: 620px) {
                padding: 0;
            }

            .plot {
                width: 100%;

                @media screen and (max-width: 620px) {
                    margin-bottom: 20px;
                }
            }

            .plot_selectors {
                color: #fff;
                font-size: 14px;
                font-weight: 700;
                margin-left: 10px;
                border-radius: 5px;
                border: 1px solid $main_green;
                display: flex;
                flex-direction: column;
                padding: 10px;

                .plot_selector {
                    display: flex;
                    flex-direction: row;
                    margin-bottom: 10px;

                    input {
                        background-color: $main_green;
                    }
                }
            }
        }

    }

    .close {
        transition: opacity .2s;
    
        &:hover {
            cursor: pointer;
            opacity: .8;
        }
    }
    
    .history_wrapper {
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;
        
        .history_text {
            color: $main_green;
            font-size: 20px;
            font-weight: 700;
        }
    }
    
    .link {
        min-width: 50px;
        cursor: pointer;
        &:hover {
            cursor: pointer;
            opacity: .8;
            //border-bottom: 2px solid $main_green;
        }
    }

    @media (max-width: 768px) {
        .plot_selectors {
            flex-direction: row; 
            flex-wrap: wrap; 
            justify-content: center; 
            padding-top: 0; 
            margin-bottom: 10px;
        }
        .plot_selector {
            margin-right: 10px; 
        }
        .plot_block {
            flex-direction: column-reverse;
            min-height: 100px !important;
        }
    }
}

.profit_positive {
    background-color: $main_green; /* или любой другой цвет, который вы выберете */
}

.profit_negative {
    background-color: #FF5252; /* или любой другой цвет */
}

.profit_neutral {
    background-color: #D9D9D9; /* или любой другой цвет */
}

.modular_wrapper_profit_screenshot {
    width: 630px;
    height: 100%;
    
    background-color: rgba(0, 0, 0, 0.5);

    display: flex;
    align-items: center;
    justify-content: center;

    .green {
        color: $main_green;
    }

    .red {
        color: #FF5252;
    }

    .red_border {
        border-color: #FF5252 !important;
    }

    .modular_item {
        width: 100%;
        max-width: 630px;
        border-radius: 5px;
        border: 2px solid $main_green;
        background: #383838;
        padding: 20px;
        box-sizing: border-box;
        position: relative;

        .toggles {
            margin-top: 10px;
            margin-bottom: 10px;
            padding: 0 20px;
            display: flex;
            width: 100%;
            justify-content: space-between;
            box-sizing: border-box;

            .switch_toggle {
                display: flex;
                flex-direction: row;
                width: auto;
                align-items: center; /* Для выравнивания по центру по вертикали */

                span {
                    font-size: 12px;
                    max-width: 50px;
                    color: #fff;
                }
                
                span:first-child {
                    font-size: 12px;
                    max-width: 50px;
                    color: #fff;
                    padding-right: 2px; /* Добавляем отступ справа для первого span */
                }
                
                span:last-child {
                    font-size: 12px;
                    max-width: 50px;
                    color: #fff;
                    padding-left: 8px; /* Добавляем отступ слева для второго span */
                }
            }
        }

        .top {
            padding-top: 20px;
            display: flex;
            flex-direction: column;

            .name_wrapper {
                display: flex;
                flex-direction: row;

                .icon {
                    width: 20px;
                    height: 20px;
                    padding-right: 10px;
                    padding-left: 10px;
                }

                .subname {
                    color: $white;
                    font-size: 16px;
                    font-weight: 600;
                }
            }

            .checkbox_area {
                padding-top: 20px;
                padding-bottom: 20px;
            }

            .info {
                color: $white;
                font-size: 16px;
                font-weight: 400;
            }
        }

        .action_wrapper {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            padding-bottom: 5px;
            margin-bottom: 5px;

            button {
                padding: 10px 30px;
                border-radius: 3px;
                background-color: $main_green;
                border: none;
                transition: opacity .2s;

                &:hover {
                    cursor: pointer;
                    opacity: .8;
                }

                span {
                    color: $main_blue;
                    font-family: Raleway;
                    font-size: 16px;
                    font-weight: 700;
                }
            }
        }

        .plot_block {
            display: flex;
            width: 100%;
            margin: 0;
            padding: 0 20px;
            box-sizing: border-box;

            min-height: 300px;

            .plot {
                width: 100%;
            }

            .plot_selectors {
                color: #fff;
                font-size: 14px;
                font-weight: 700;
                margin-left: 10px;
                border-radius: 5px;
                border: 1px solid $main_green;
                display: flex;
                flex-direction: column;
                padding: 10px;

                .plot_selector {
                    display: flex;
                    flex-direction: row;
                    margin-bottom: 10px;

                    input {
                        background-color: $main_green;
                    }
                }
            }
        }

    }

    .close {
        transition: opacity .2s;
    
        &:hover {
            cursor: pointer;
            opacity: .8;
        }
    }
    
    .history_wrapper {
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;
        
        .history_text {
            color: $main_green;
            font-size: 20px;
            font-weight: 700;
        }
    }
    
    .link {
        min-width: 50px;
        cursor: pointer;
        &:hover {
            cursor: pointer;
            opacity: .8;
            //border-bottom: 2px solid $main_green;
        }
    }

    @media (max-width: 768px) {
        .plot_selectors {
            flex-direction: row; 
            flex-wrap: wrap; 
            justify-content: center; 
            padding-top: 0; 
            margin-bottom: 10px;
        }
        .plot_selector {
            margin-right: 10px; 
        }
        .plot_block {
            flex-direction: column-reverse;
            min-height: 100px !important;
        }
    }
}

.share_button {
    position: absolute;
    bottom: 5px;
    right: 5px;

    cursor: pointer;
}

.screenshot_container {
    padding: 100px 20px 20px 20px;
    background: linear-gradient(130.5deg, rgba(145, 230, 23, 0.5) 0%, rgba(5, 0, 51, 0.5) 99.45%);
    box-sizing: border-box;
    position: relative;

    .screenshot_logo {
        position: absolute;
        top: 10px;
        right: 20px;
    }
}

.modular_wrapper_share {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;

    display: flex;
    align-items: center;
    justify-content: center;

    .green {
        color: $main_green;
    }

    .red {
        color: #FF5252;
    }

    .red_border {
        border-color: #FF5252 !important;
    }

    .modular_item {
        width: 100%;
        max-width: 530px;
        border-radius: 5px;
        border: 2px solid $main_green;
        background: $main_blue;
        padding: 20px;
        box-sizing: border-box;

        .top {
            padding-top: 20px;
            display: flex;
            flex-direction: column;

            .name_wrapper {
                display: flex;
                flex-direction: row;

                .icon {
                    width: 20px;
                    height: 20px;
                    padding-right: 10px;
                    padding-left: 10px;
                }

                .name {
                    color: $white;
                    font-size: 18px;
                    font-weight: 700;

                }

                .subname {
                    color: $white;
                    font-size: 12px;
                    font-weight: 400;
                }

                .share_icons {
                    display: flex;
                    gap: 10px;
                }
            }

            .info {
                color: $white;
                font-size: 16px;
                font-weight: 400;
            }
        }

        .action_wrapper {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            padding-top: 15px;
            padding-bottom: 5px;
            margin-bottom: 5px;

            button {
                padding: 10px 20px;
                margin: 0 5px;
                border-radius: 3px;
                border: none;
                transition: opacity .2s;

                &:hover {
                    cursor: pointer;
                    opacity: .8;
                }

                span {
                    color: $main_blue;
                    font-family: Raleway;
                    font-size: 16px;
                    font-weight: 700;
                }
            }

            .red_button {
                background-color: #FF5252;
                span {
                    color: #fff;
                }
            }
        }

    }
}