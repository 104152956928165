@import '../../style/colors.scss';

.auth_container {
    position: relative;
    width: 100%;
    min-height: 95vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin: 20px;
    background-image: url('../img/bg_img_login.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 5px;

    @media screen and (max-width: 680px) {
        padding: 5px;
        margin: 10px 0;
    }

    .header_text,
    .subheader_text {
        color: $white;
        text-align: center;
        font-family: Raleway;
        font-size: 25px;
        font-weight: 700;
    }

    .header_text {
        color: $main_green;
        margin-top: 30px;
        margin-bottom: 10px;

        @media screen and (max-width: 680px) {
            font-size: 24px;
        }
    }

    .green_text {
        color: $main_green;
    }

    .subheader_text {
        margin-bottom: 50px;
        font-size: 20px;

        @media screen and (max-width: 680px) {
            font-size: 14px;
        }

        &.code_page {
            span {
                color: $main_green;
            }
        }
    }

    .successText {
        color: $main_green;
        font-size: 35px;
        font-weight: bold;
        margin-top: 10px;
        margin-bottom: 20px;
        text-align: center;
    }

    .button_wrapper {
        display: flex;
        align-items: center;


        .button {
            max-width: 180px;  
            min-width: 130px;  
            width: 100%;
            
            &:first-child {
                margin-right: 30px;
            }
        }

        .second_button {
            background-color: transparent;
            border: 2px solid $main_green;
            color: $main_green;

            &:hover {
                background-color: transparent;
                border-color: $main_green_hover;
            }
        }
    }

    form {
        box-sizing: border-box;
        background-color: $main_blue;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 5px;
        border: 3px solid $main_green;
        padding: 154px 150px;
        width: 100%;
        max-width: 600px;
        margin: 24px 0;

        @media screen and (max-width: 680px) {
            padding: 32px 16px;
        }

        &.signup_form {
            padding: 52px 150px;

            @media screen and (max-width: 680px) {
                padding: 32px 16px;
            }

            .bottom_wrapper {
                label {
                    span {
                        &:last-child {
                            font-size: 11px;

                            a {
                                font-size: 11px;
                            }
                        }
                    }
                }
            }
        }
    }

    &.reset {

        form {
            padding: 20px 140px;
            max-width: 860px;
            min-height: 704px;
            display: flex;
            align-items: center;
            //justify-content: center;

            @media screen and (max-width: 680px) {
                padding: 32px 16px;
                min-height: unset;
            }
        }

        .centered {
            justify-content: center;
        }

        img {
            @media screen and (max-width: 680px) {
                max-width: 120px;
            }
        }

        .inputs_wrapper {
            margin-bottom: 30px;
            max-width: 415px;

            @media screen and (max-width: 680px) {
                margin-bottom: 30px;
            }
        }

        .button {
            max-width: 200px;
            width: 100%;
            margin-bottom: 40px;
        }
    }

    .header {
        display: block;
        text-align: center;
        color: $white;
        font-size: 35px;
        font-weight: 700;
        margin-bottom: 40px;

        &.under_img {
            margin-top: 30px;
            margin-bottom: 20px;
        }

        &.new_password {
            margin-bottom: 50px;
        }

        @media screen and (max-width: 680px) {
            font-size: 24px;
        }
    }

    .header_padding {
        padding-top: 40px;
    }

    .back_link {
        width: 140%;
        margin-bottom: 20px;

        a {
            color: $main_green;
            text-decoration-line: underline;
            transition: color .2s;

            &:hover {
                cursor: pointer;
                color: $main_green_hover;
            }
        }

        @media screen and (max-width: 680px) {
            width: 100%;
        }
    }

    .inputs_wrapper {
        display: flex;
        flex-direction: column;
        max-width: 300px;
        width: 100%;
        margin-bottom: 15px;
    
        .item {
            position: relative;
            display: flex;
            flex-direction: column;
            width: 100%;

            svg {
                position: absolute;
                right: 8px;
                bottom: 10px;
                fill: #828099;
                opacity: 0.8;

                &:hover {
                    cursor: pointer;
                }
            }
            
            &:not(:last-child) {
                margin-bottom: 20px;
            }
    
            label {
                color: $white;
                font-size: 16px;
                font-weight: 600;
                margin-bottom: 10px;

                @media screen and (max-width: 680px) {
                    font-size: 14px;
                }
            }
    
            input {
                height: 19px;
                border-radius: 3px;
                outline: none;
                border: none;
                background-color: $white;
                padding: 13px 10px;
                color: $main_blue;
                font-family: Raleway;
                font-size: 16px;

                @media screen and (max-width: 680px) {
                    font-size: 14px;
                    height: 16px;
                }
            }
        }
    }

    .code_wrapper {
        display: flex;
        
        flex-direction: row;
        justify-content: space-between;
        
        //width: 70%;
        margin-bottom: 15px;

        .item_code {
            position: relative;
            display: flex;
            flex-direction: row;
            margin-right: 10px;
            
            width: 50px;

            &:last-child {
                margin-right: 0px;
            }

            @media screen and (max-width: 680px) {
                margin-right: 5px;
            }

            svg {
                position: absolute;
                right: 8px;
                bottom: 10px;
                fill: #828099;
                opacity: 0.8;

                &:hover {
                    cursor: pointer;
                }
            }
            
            &:not(:last-child) {
                margin-bottom: 20px;
            }
    
            label {
                color: $white;
                font-size: 16px;
                font-weight: 600;
                margin-bottom: 10px;

                @media screen and (max-width: 680px) {
                    font-size: 14px;
                }
            }

            .code_input {
                width: 30px;
                text-align: center;
            }
    
            input {
                height: 19px;
                border-radius: 3px;
                outline: none;
                border: none;
                background-color: $white;
                padding: 13px 10px;
                color: $main_blue;
                font-family: Raleway;
                font-size: 20px;

                @media screen and (max-width: 680px) {
                    font-size: 14px;
                    height: 16px;
                }
            }
        }
    }

    .bottom_wrapper {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;
        max-width: 300px;

        label {
            color: $white;
        }

        a {
            color: $main_green;
            font-size: 14px;
            text-decoration-line: underline;
            transition: color .2s;

            &:hover {
                cursor: pointer;
                color: $main_green_hover;
            }
        }
    }

    .button_container {
        margin-top: 50px;
        width: 80%;
        display: flex;
        justify-content: space-around;
    }

    .button {
        display: block;
        width: 100%;
        border-radius: 3px;
        background: $main_green;
        padding: 13px 20px;
        color: $main_blue;
        font-weight: 700;
        border: none;
        margin-bottom: 20px;
        transition: background-color .2s;
        max-width: 300px;

        &:hover {
            cursor: pointer;
            background-color: $main_green_hover;
        }
    }

    .button_inactive {
        display: block;
        border-radius: 3px;
        background: $border_color;
        padding: 13px 20px;
        color: $main_blue;
        font-weight: 700;
        border: none;
        margin-bottom: 20px;
        max-width: 200px;

        &:hover {
            cursor: pointer;
            background-color: $border_color;
        }
    }

    .outline_button {
        display: block;
        //width: 100%;
        border-radius: 3px;
        background-color: transparent;
        padding: 13px 20px;
        font-weight: 700;
        border: 2px solid $main_green;
        color: $main_green;

        margin-bottom: 20px;
        transition: background-color .2s;
        max-width: 200px;

        &:hover {
            cursor: pointer;
            background-color: transparent;
            border-color: $main_green_hover;
        }
    }

    .bottom_element {
        color: $white;
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 6px;

        a {
            color: $main_green;
            text-decoration-line: underline;
            transition: color .2s;

            &:hover {
                cursor: pointer;
                color: $main_green_hover;
            }
        }
    }

    .bottom_element_2 {
        font-size: 14px;
        font-weight: 500;
        //margin-bottom: 6px;
        //margin-left: 6px;
        display: flex;

        img {
            width: 20px;
            height: 20px;
        }

        p {
            padding-bottom: 10px;
            padding-left: 10px;
            margin: 0;
        }
    }

}