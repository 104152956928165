@import './colors.scss';

.bet_item {
    color: $white;
    flex-wrap: nowrap !important; 

    p {
        font-family: Raleway;
        font-size: 16px;
    }

    .leg_name {
        color: #827F99;
    }
}

.ev_bet_item {
    @media screen and (max-width: 620px) {
        padding-bottom: 40px;
    }
}

.bordered_item {
    padding: 5px 18px;
    border: 1px solid $main_green;
    border-radius: 3px;
    height: fit-content;
    margin: 0 10px;
}

.bet_item_container {
    width: 100%;

    .bet_item {
        display: flex;
        align-items: center;
    }

    @media screen and (max-width: 620px) {
        position: relative;
        padding-bottom: 60px;
    }
}

.calculate_item {
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    @media screen and (max-width: 620px) {
        position: absolute;
        left: 8px;
        bottom: 6px;
        width: fit-content;

        &.right_item {
            left: unset;
            right: 0;
            bottom: 12px;
        }
    }
}

.item_massage {
    position: absolute;
    right: 30px;
    bottom: 20px;

    span {
        color: $main_yellow;
        font-size: 14px;
        font-weight: 600;
    }
}

.calculate_wrapper {

    .calculate_items {
        padding: 5px;
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        justify-content: space-around;

        @media screen and (max-width: 620px) {
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center;
            padding: 0;
        }
    }

    @media screen and (max-width: 620px) {
        position: absolute;
        left: 0;
        bottom: 6px;
        width: 100%;
        max-width: unset !important;
    }
}

.Calculate_container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: $main_green;
    width: 100%;
    height: 100%;
    border-radius: 10px 10px 0 0;

    @media screen and (max-width: 620px) {
        justify-content: flex-start !important;

        &:first-child {
            justify-content: flex-end !important;
            padding-left: 10px;
        }

        &:last-child {
            justify-content: flex-start !important;
        }
    }

    p {
        font-weight: 600;
        text-align: right;
        margin-right: 5px;

        @media screen and (max-width: 620px) {
            text-align: left;
        }
    }

    .white_text {
        color: $white;
    }

    .remove_button {
        color: #FF5252;
        
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        padding-top: 5px;
    }

    img {
        @media screen and (max-width: 620px) {
            width: 15px;
            height: 15px;
        }
    }
}

.calculate_ev {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: $main_green;
    width: 100%;
    height: 100%;
    border-radius: 10px 10px 0 0;

    @media screen and (max-width: 620px) {
        justify-content: flex-start !important;
    }

    p {
        font-weight: 600;
        text-align: right;
        margin-right: 5px;

        @media screen and (max-width: 620px) {
            text-align: left;
        }
    }

    .white_text {
        color: $white;
    }

    img {
        @media screen and (max-width: 620px) {
            width: 15px;
            height: 15px;
        }
    }
}

.bookmaker_name {
    display: flex;
    align-items: center;
    
    img {
        width: 30px;
        height: 30px;
        object-fit: contain;
        object-position: center;
        border-radius: 2px;
        margin-right: 10px;

        @media screen and (max-width: 800px) {
            width: 24px;
            height: 24px;
            margin-right: 0;
            margin-bottom: 5px;     
        }
    }

    span {
        @media screen and (max-width: 800px) {
            font-size: 9px;
        }
    }

    @media screen and (max-width: 800px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start !important;
        flex-basis: unset !important;
        padding-left: 0 !important;
    }
}

.bets_input {
    background-color: transparent;
    text-align: center;
    color: $white;
    width: 100%;
    border: none;
    outline: none;

    font-size: 14px;
    font-weight: 400;

    font-family: 'Mulish', sans-serif;
}

.save_container {
    padding-top: 10px;
    width: 100%;
    display: flex;
    justify-content: end;

    .save_button {
        background-color: $main_green;
        font-size: 16px;
        font-weight: 700;
        font-family: 'Raleway';
        padding: 5px 12px;
        border: none;
        border-radius: 3px;
        transition: background-color .2s;

        &:hover {
            cursor: pointer;
            background-color: $main_green_hover;
        }

        @media screen and (max-width: 760px) {
            font-size: 11px;
        }
    }

    .cancel_button {
        background-color: $main_blue;
        color: $main_green;
        font-size: 16px;
        //font-weight: 700;
        font-family: 'Raleway';
        padding: 5px 12px;
        border: 1px solid $main_green;
        transition: background-color .2s;
        margin-right: 10px;

        &:hover {
            cursor: pointer;
        }

        @media screen and (max-width: 760px) {
            font-size: 11px;
        }
    }
}

.share_container {
    padding-top: 5px;
    width: 100%;
    display: flex;
    justify-content: end;

    div {
        padding: 2px;
        background-color: #37345C;
        border-radius: 5px;
        cursor: pointer;

        @media screen and (max-width: 760px) {
            position: absolute;
            right: 10px;
            bottom: 10px;
        }
    }
}

.info_container {
    padding-top: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: #FFF700;
}


.attention_container {
    font-size: 16px;
    font-weight: 600;
    color: #FF5252;
}