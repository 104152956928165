@import './colors.scss';

.header_box {
    .header_bar {
        background-color: $main_blue;
    }

    .button {
        padding: 6px 0;
        margin: 0 12.5px;
        min-width: unset;
        text-decoration: none;
        font-weight: 600;
        text-transform: uppercase;
    }

    .badge {
        position: relative;
        top: 5px;
        right: 10px;
        width: 10px;
        height: 10px;
        background-color: red;
        color: white;
        border-radius: 50%;
        //padding: 2px 5px;
        //font-size: 10px;
      }

    .mobile {
        display: flex;
        align-items: center;
        margin-top: 4px;

        a {
            display: flex;
            align-items: center;
        }

        @media screen and (max-width: 1080px) {
            display: none;
        }
    }

    .header_container {
        display: flex;
        justify-content: space-between;
        height: 100%;
    }

    .logo {
        padding-top: 5px;
        display: flex;
        align-items: center;

        @media screen and (max-width: 1080px) {
            padding-top: 0;
        }

        img {
            @media screen and (max-width: 1080px) {
                max-width: 120px;
            }
        }

        .status_wrapper {
            background: repeating-linear-gradient(to right, $main_green, #CBF391, $white);
            border-radius: 20px;
            position: relative;
            z-index: 2;
            padding: 10px 12px;
            margin-left: 20px;

            @media screen and (max-width: 1080px) {
                margin-left: 10px;
            }

              &:after {
                content: '';
                background: $main_blue; 
                border-radius: 20px; 
                position: absolute; 
                left: 2px; 
                right: 2px; 
                top: 2px; 
                bottom: 2px;
                z-index: -1;
            }

            span {
                letter-spacing: 0.6px;
                font-size: 14px;
                font-weight: 700;
                background: linear-gradient(90deg, $main_green, #CBF391, $white);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            &.free {
                background: repeating-linear-gradient(to right, #828099, $white);

                span {
                    background: linear-gradient(90deg, #828099, $white);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }
        
        @media screen and (min-width: 1081px) {
            position: absolute;
            left: 50%;
            top: 0;
            transform: translateX(-50%);
        }
    }

    .right_header {
        height: 100%;
        display: flex;
        align-items: center;

        .admin_button {
            position: relative;
            color: $white;
            font-family: Raleway;
            font-weight: 600;
            text-decoration: none;
            margin-right: 20px;
            transition: color .2s;

            @media screen and (max-width: 1080px) {
                display: none;
            }

            &.active {
                color: $main_green;

                &:after {
                    left: 50%;
                    width: 100%;
                    bottom: -5px;
                    transform: translateX(-50%);
                    height: 3px;
                    content: "";
                    position: absolute;
                    transition: all 0.3s ease;
                    background-color: $main_green;
                }
            }
        }

        .how_to_wrapper {
            background: repeating-linear-gradient(to right, $main_green, #CBF391, $white);
            border-radius: 20px;
            position: relative;
            z-index: 2;
            padding: 10px 12px;
            margin-right: 5px;
            display: flex;
            align-items: center;

            @media screen and (max-width: 1080px) {
                margin-left: 10px;
            }

            @media (max-width: 560px) {
                span {
                    display: none; 
                    
                }

                svg {
                    padding-right: 0px !important;
                }
            }

            &:after {
                content: '';
                background: $main_blue; 
                border-radius: 20px; 
                position: absolute; 
                left: 2px; 
                right: 2px; 
                top: 2px; 
                bottom: 2px;
                z-index: -1;
            }

            span {
                letter-spacing: 0.6px;
                font-size: 14px;
                font-weight: 700;
                background: #fff;
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            svg {
                padding-right: 5px;
                font-size: 18px;
                font-weight: 700;
                background: linear-gradient(90deg, $main_green, #CBF391, $white);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            &.free {
                background: repeating-linear-gradient(to right, #828099, $white);

                span {
                    background: linear-gradient(90deg, #828099, $white);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }
    }

    .setting_button {
        position: relative;
        &.active {
            &:after {
                left: 50%;
                width: 50%;
                bottom: 0;
                transform: translateX(-50%);
                height: 3px;
                content: "";
                position: absolute;
                transition: all 0.3s ease;
                background-color: $main_green;
            }
        }
    }
}

.exit_btn {
    background: transparent;
    border: none;
    padding: 10px;
    transition: opacity .2s;

    &:hover {
        cursor: pointer;
        opacity: .8;
    }
}

.modular_wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;

    display: flex;
    align-items: center;
    justify-content: center;

    .green {
        color: $main_green;
    }

    .red {
        color: #FF5252;
    }

    .modular_item {
        width: 100%;
        max-width: 530px;
        border-radius: 5px;
        border: 2px solid $main_green;
        background: $main_blue;
        padding: 20px;
        box-sizing: border-box;

        .top {
            padding-top: 0px;
            display: flex;
            flex-direction: column;

            .name_wrapper {
                display: flex;
                flex-direction: row;

                .icon {
                    width: 20px;
                    height: 20px;
                    padding-right: 10px;
                    padding-left: 10px;
                }

                .name {
                    color: $main_green;
                    font-size: 20px;
                    font-weight: 700;

                }

                .subname {
                    color: $white;
                    font-size: 16px;
                    font-weight: 500;
                }
            }

            .info {
                color: $white;
                font-size: 16px;
                font-weight: 400;
            }
        }

        .action_wrapper {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            padding-top: 15px;
            //padding-bottom: 5px;
            margin-bottom: 5px;

            button {
                padding: 10px 20px;
                margin: 0 5px;
                border-radius: 3px;
                border: none;
                transition: opacity .2s;

                &:hover {
                    cursor: pointer;
                    opacity: .8;
                }

                span {
                    color: $main_blue;
                    font-family: Raleway;
                    font-size: 16px;
                    font-weight: 700;
                }
            }

            .cancel_button {
                font-weight: 500;
            }

            .green_button {
                background-color: $main_green;
                
            }
        }

    }
}