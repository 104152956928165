@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500;600;700;800;900&display=swap');
@import './colors.scss';

html, body {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    max-width: 100vw;
    overflow-x: hidden;
}
  
body {
    font-family: 'Raleway', sans-serif;
}

.MuiSwitch-root {
    margin-right: 0 !important;
    margin-left: 10px !important;
}

.MuiButtonBase-root {
    &.Mui-checked + .MuiSwitch-track {
        background-color: $main_green !important;
    }
}

.MuiSwitch-track {
    background-color: rgba(255, 255, 255, 0.50) !important;
    margin-right: 0 !important;
}

.MuiSwitch-thumb {
    background-color: $main_blue !important;
}

.preferences_wrapper,
.admin_select {
    .MuiInputBase-root {
        background-color: $white;
        
        .MuiSelect-select {
            color: $main_blue !important;
            min-height: unset;
            height: 19px;
            padding: 13px 10px;
        }

        svg {
            fill:  $main_blue;;
        }
    }
}

.tooltips {
    background-color: $white !important;
    color: #000 !important;
    font-weight: 500 !important;
    z-index: 100 !important;
}