@import './colors.scss';

.calculator_wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    background-color: $main_blue;
    padding: 4px 20px 20px;
    border-radius: 5px;
    transform: translateY(-60px);
    margin-bottom: -60px;

    @media screen and (max-width: 1080px) {
        flex-direction: column;
        transform: translateY(-26px);
        margin-bottom: -26px;
    }

    @media screen and (max-width: 620px) {
        padding: 4px 16px 16px;
        transform: translateY(-30px);
        margin-bottom: -30px;
    }
}

.inactive_wrapper {
    opacity: 0.5;
    background-color: #808080;
    pointer-events: none;
}

.inner_block {
    pointer-events: auto;
}

.no_bets {
    color: $main_blue;
    font-weight: 700;
    width: 100%;
    padding: 20px 10px 0;

    @media screen and (max-width: 620px) {
        padding: 16px 10px 0;
    }
}

.calculator_switch {
    width: 100%;
    background: linear-gradient(180deg, #050033 79.69%, rgba(5, 0, 51, 0.50) 100%);
    border-radius: 5px;
    color: $white;

    @media screen and (max-width: 1080px) {
        display: flex;
        justify-content: flex-end;
    }

    .switch_button {
        width: fit-content;
        position: relative;
        z-index: 10;
        display: flex;
        align-items: center;
        padding: 20px;  
        transition: opacity .2s;

        @media screen and (max-width: 620px) {
            padding: 16px;  
        }

        &:hover {
            cursor: pointer;
            opacity: .8;
        }

        span {
            font-weight: 600;
            margin: 0 10px;

            @media screen and (max-width: 1080px) {
                display: none;
            }
        }
    
        .switch_arrow {
            height: 15px;
            width: 15px;
            object-fit: contain;
            object-position: center;
            transition: transform .2s;

            @media screen and (max-width: 1080px) {
                margin-left: 10px;
            }

            &.active {
                transform: rotate(180deg);
            }
        }
    }
}

.root {
    width: 100%;

    p, span, input {
        @media screen and (max-width: 920px) {
            font-size: 14px;
        }

        @media screen and (max-width: 760px) {
            font-size: 10px;
        }
    }

    .header {
        width: 35%;
        margin: 0 20px 5px 0;

        @media screen and (max-width: 1080px) {
            width: 100%;
            margin-right: unset;
        }

        .icon {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            
            span {
                font-weight: 600;
                color: $white;
    
                &::first-letter {
                    text-transform: uppercase;
                }
    
                &:first-child {
                    color: $main_green;
                }
            }
    
            .delimiter {
                margin: 0 15px;
                color: rgba(255, 255, 255, 0.30);
                font-size: 20px;
            }
        }
    }

    .calculator_table {
        width: 57%;

        @media screen and (max-width: 1080px) {
            width: 100%;
        }

        table {
            border-collapse: unset;
            border-spacing: 0 5px;
        } 

        thead {

            th {
                border-bottom: unset;

                @media screen and (max-width: 760px) {
                    padding: 2px;
                    letter-spacing: normal;
                }
            }

            span {
                color: $white;
                font-family: Raleway;
                font-weight: 600;
            }
        }

        tbody {
            tr {
                padding: 2px 10px;
                width: 100%;
                height: 48px;
                background: $white;
                box-sizing: border-box;
            }

            td {
                border-top: 2px solid $main_green;
                border-bottom: 2px solid $main_green;

                &:first-child {
                    border-left: 2px solid $main_green;
                    border-top-left-radius: 3px;
                    border-bottom-left-radius: 3px;
                }

                &:last-child {
                    border-right: 2px solid $main_green;
                    border-top-right-radius: 3px;
                    border-bottom-right-radius: 3px;
                }
            }

            .border_red {
                td {
                    border-top: 2px solid #FF5252;
                    border-bottom: 2px solid #FF5252;

                    &:first-child {
                        border-left: 2px solid #FF5252;
                    }

                    &:last-child {
                        border-right: 2px solid #FF5252;
                    }
                }
                
            }

            td, td input {
                padding: 5px 0;
                color: $main_blue;
                font-family: Raleway;
                font-weight: 600;
            }

            td input {
                padding: 10px 5px;
            }
        }

        th {
            background-color: transparent;
        }

        .value_input {
            width: 80px;

            @media screen and (max-width: 480px) {
                width: 50px;
            }
        }
    }
}

.calculator_input,
.action_item_inline input {
    display: flex;
    align-items: center;
    padding: 14px 10px;
    width: 100%;
    height: 48px;
    border-radius: 3px;
    border: 2px solid $main_green;
    background: $white;
    box-sizing: border-box;

    color: $main_blue;
    font-family: Raleway;
    font-size: 16px;
    font-weight: 600;

    @media screen and (max-width: 920px) {
        font-size: 14px;
    }

    @media screen and (max-width: 760px) {
        font-size: 10px;
    }
}

.input_red {
    input {
        border: 2px solid #FF5252;
    }
}

.input_field_red {
    border: 2px solid #FF5252;
}

.text_red {
    color: #FF5252 !important;
}

.info_container {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 14px;
    color: #FFF700;
}

.calculator_action {
    width: 8%;
    min-width: 170px;
    margin-bottom: 5px;
    margin-left: 20px;
    display: flex;
    align-items: flex-end;
    flex-direction: column;

    @media screen and (max-width: 1080px) {
        width: 100%;
        margin-left: unset;
    }

    @media screen and (max-width: 620px) {
        margin-left: 16px;
    }
}

.action_item_select {
    margin-bottom: 5px;

    & > div > div {
        padding: 0 14px !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    input,
    & > div > div {
        height: 40px !important;

        @media screen and (max-width: 920px) {
            font-size: 14px;
        }

        @media screen and (max-width: 760px) {
            font-size: 10px;
        }
    }
}

.total_input {
    position: relative;

    & > div {
        padding: 0;
    }

    & > div > div {
        position: absolute;
        top: 55%;
        transform: translateY(-50%);
    }

    .action_inline_symbol {
        p {
            color: $main_blue !important;
            font-family: Raleway !important;
            font-size: 16px !important;
            font-weight: 600 !important;

            @media screen and (max-width: 920px) {
                font-size: 14px !important;
            }
    
            @media screen and (max-width: 760px) {
                font-size: 11px !important;
            }
        }

        &.start {
            left: 10px;
        }

        &.end {
            right: 10px;
        }
    }
}

.action_item_inline {
    input {
        max-width: 120px;
    }
}

.action_item {
    display: flex;
    align-items: center;
    justify-content: center;
    
    span {
        color: $white;
        font-weight: 600;
        margin-right: 10px;
    }

    input {
        font-weight: 700;
        height: 40px !important;
        padding: 0 50px 0 26px;
    }

    legend {
        span {
            margin: 0;
        }
    }
}

.action_button {
    border-radius: 3px;
    border: 2px solid $white;
    background: $main_green;
    color: $main_blue;
    font-weight: 700;
    padding: 10px 20px;
    font-family: Raleway;
    margin-top: 10px;
    transition: background-color .2s;

    &:hover {
        background-color: $main_green_hover;
        cursor: pointer;
    }

    &:disabled {
        opacity: 0.5; 
        cursor: not-allowed;
    }
}

.modular_wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;

    display: flex;
    align-items: center;
    justify-content: center;

    .green {
        color: $main_green;
    }

    .red {
        color: #FF5252;
    }

    .red_border {
        border-color: #FF5252 !important;
    }

    .modular_item {
        width: 100%;
        max-width: 530px;
        border-radius: 5px;
        border: 2px solid $main_green;
        background: $main_blue;
        padding: 20px;
        box-sizing: border-box;

        .top {
            padding-top: 20px;
            display: flex;
            flex-direction: column;

            .name_wrapper {
                display: flex;
                flex-direction: row;

                .icon {
                    width: 20px;
                    height: 20px;
                    padding-right: 10px;
                    padding-left: 10px;
                }

                .subname {
                    color: $white;
                    font-size: 16px;
                    font-weight: 600;
                }
            }

            .checkbox_area {
                padding-top: 20px;
                padding-bottom: 20px;
            }

            .info {
                color: $white;
                font-size: 16px;
                font-weight: 400;
            }
        }

        .action_wrapper {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            padding-bottom: 5px;
            margin-bottom: 5px;

            button {
                padding: 10px 30px;
                border-radius: 3px;
                background-color: $main_green;
                border: none;
                transition: opacity .2s;

                &:hover {
                    cursor: pointer;
                    opacity: .8;
                }

                span {
                    color: $main_blue;
                    font-family: Raleway;
                    font-size: 16px;
                    font-weight: 700;
                }
            }
        }

    }

    .modular_item_calculator {
        width: 100%;
        max-width: 630px;
        border-radius: 5px;
        border: 2px solid $main_green;
        background: #383838;
        padding: 20px;
        box-sizing: border-box;

        .top {
            padding-top: 20px;
            display: flex;
            flex-direction: column;

            .name_wrapper {
                display: flex;
                flex-direction: row;

                .icon {
                    width: 20px;
                    height: 20px;
                    padding-right: 10px;
                    padding-left: 10px;
                }

                .subname {
                    color: $white;
                    font-size: 16px;
                    font-weight: 600;
                }
            }

            .checkbox_area {
                padding-top: 20px;
                padding-bottom: 20px;
            }

            .info {
                color: $white;
                font-size: 16px;
                font-weight: 400;
            }
        }

        .action_wrapper {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            padding-bottom: 5px;
            margin-bottom: 5px;

            button {
                padding: 10px 30px;
                border-radius: 3px;
                background-color: $main_green;
                border: none;
                transition: opacity .2s;

                &:hover {
                    cursor: pointer;
                    opacity: .8;
                }

                span {
                    color: $main_blue;
                    font-family: Raleway;
                    font-size: 16px;
                    font-weight: 700;
                }
            }
        }

        .plot_block {
            display: flex;
            width: 100%;
            margin: 0;
            padding: 0;

            min-height: 300px;

            .plot {
                width: 100%;
            }

            .plot_selectors {
                color: #fff;
                font-size: 14px;
                font-weight: 700;
                margin-left: 10px;
                border-radius: 5px;
                border: 1px solid $main_green;
                display: flex;
                flex-direction: column;
                padding: 10px;

                .plot_selector {
                    display: flex;
                    flex-direction: row;
                    margin-bottom: 10px;

                    input {
                        background-color: $main_green;
                    }
                }
            }
        }

    }

    @media (max-width: 768px) {
        .plot_selectors {
            flex-direction: row; 
            flex-wrap: wrap; 
            justify-content: center; 
            padding-top: 0; 
            margin-bottom: 10px;
        }
        .plot_selector {
            margin-right: 10px; 
        }
        .plot_block {
            flex-direction: column-reverse;
            min-height: 100px !important;
        }
    }
}

.close {
    transition: opacity .2s;

    &:hover {
        cursor: pointer;
        opacity: .8;
    }
}

.history_wrapper {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    
    .history_text {
        color: $main_green;
        font-size: 20px;
        font-weight: 700;
    }
}

.link {
    min-width: 50px;
    cursor: pointer;
    &:hover {
        cursor: pointer;
        opacity: .8;
        //border-bottom: 2px solid $main_green;
    }
}

.plot_selector {
    position: relative;
    display: inline-block;
    margin-right: 10px;
  }
  
  /* Скрываем оригинальную радиокнопку */
  .plot_selector input[type="radio"] {
    display: none;
  }
  
  /* Стили для кастомной радиокнопки */
  .plot_selector label {
    cursor: pointer;
    padding-left: 25px;
    position: relative;
  }
  
  /* Добавляем кастомный кружок перед текстом в label */
  .plot_selector label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 12px;
    height: 12px;
    border: 2px solid #ddd;
    border-radius: 50%;
    background-color: #fff;
  }
  
  /* Стиль для кружка при выборе радиокнопки */
  .plot_selector input[type="radio"]:checked + label:before {
    background-color: $main_green;
    border-color: $main_green;
  }
  
  /* Опционально: стиль для текста при выборе */
  .plot_selector input[type="radio"]:checked + label {
    color: $main_green; 
  }

.odds_field {
    position: relative;
    height: 100%;
    padding: 0;
    margin: 0;
    border: 1px solid $main_green;
    border-radius: 3px;
    margin-left: 5px;
    margin-right: 5px;

    span {
        display: block;
        padding-top: 8px;
        padding-bottom: 8px;
    }
}

.odds_field_selected {
    color: $main_green;
    background-color: #383838;
}

.reload_icon {
    position: absolute;
    top: 0;
    right: 0;
    width: 12px;
    height: 12px;
    margin-right: 2px;
    margin-top: 2px;
    cursor: pointer;
}

.input_adornment {
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding: 0;
    margin: 0;
}

.stake_input {
    div {
        padding-left: 5px;     
    }
}

.link_button {
    display: inline-block;
    background-color: $main_green;
    color: #000;
    font-size: 11px;
    padding: 5px;
    border-radius: 4px;
    text-decoration: none;
    text-align: center;
    border: none;
    margin-left: 5px;
    cursor: pointer;

    &:hover {
        cursor: pointer;
        //opacity: .8;
        background-color: $main_green_hover;
    }
}