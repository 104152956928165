@import '../../components/style/colors.scss';

.filter_wrapper {
    height: 100%;
    margin-left: 20px;
    width: calc(24.3% - 20px);

    &.hide {
        max-width: 74px;
    }

    @media screen and (max-width: 1080px) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        margin: 0;
        z-index: 100;
        display: none;

        &.active {
            display: block;
        }
    }
}

.betslist_wrapper {
    width: calc(75.7% - 40px);
    height: calc(100% - 5px);
    margin: 5px 20px;

    &.full_width {
        width: calc(100% - 134px);
    }

    @media screen and (max-width: 1080px) {
        width: calc(100% - 40px);
    }

    @media screen and (max-width: 580px) {
        width: 100%;
        margin: 5px 0;
    }
}

.calculator_wrapper {
    width: 100%;

    .calculator_container {
        width: calc(100% - 40px);
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 20px;

        @media screen and (max-width: 580px) {
            width: 100%;
            margin: 20px 0;
        }
    }
}

.mobile_header {
    width: 100%;
    margin: 16px -5px -6px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
        display: flex;
        align-items: center;
    }

    &.submenu {
        margin-bottom: 10px;
    }

    @media screen and (min-width: 1081px) {
        display: none;
    }

    a {
        color: rgba(5, 0, 51, 0.70);
        font-family: Raleway;
        font-size: 14px;
        font-weight: 500;
        display: inline-block;
        margin: 0 5px;
        text-decoration: none;
    }
}

.mobile_subheader {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 20px 0;
    width: 100%;
    border-radius: 0px 0px 3px 3px;

    .filter_icon {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);

        @media screen and (min-width: 1081px) {
            display: none;
        }
    }

    .mobile_links {
        width: fit-content;
        background: linear-gradient(180deg, rgba(5, 0, 51, 0.03) 0%, rgba(5, 0, 51, 0.15) 100%);
    }

    @media screen and (max-width: 620px) {
        margin: 6px 16px -4px;
    }

    .category_name {
        display: block;

        .category_mode {
            color: $main_blue;
            font-size: 16px;
            font-weight: 700;
            text-transform: uppercase;
        }

        @media screen and (max-width: 620px) {
            display: none;
        }
    }

    a {
        text-decoration: none;
        color: $main_blue;
    }

    .button {
        font-size: 16px;
        font-weight: 400;
        padding: 10px;
        display: inline-block;
        min-width: unset;
        font-family: 'Raleway', sans-serif;
        color: $main_blue;
        min-width: 100px;
        text-align: center;

        @media screen and (max-width: 620px) {
            font-size: 15px;
        }
    }

    .button_active {
        font-weight: 600;
        border-radius: 0px 0px 3px 3px;
        background: linear-gradient(180deg, rgba(145, 230, 23, 0.20) 0%, #91E617 100%);
    }
}