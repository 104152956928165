@import '../../style/colors.scss';

.account_wrapper {
    position: relative;
    width: 100%;
    min-height: calc(100vh - 120px);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
    
    @media screen and (max-width: 1080px) {
        flex-direction: column;
        padding: 0;
        width: 100%;
    }

    .menu {
        position: sticky;
        top: 20px;
        left: 0;
        width: 20%;
        margin-right: 20px;
        background-color: $main_blue;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 6px 20px 20px;
        min-height: calc(100vh - 120px);

        @media screen and (max-width: 1080px) {
            flex-direction: row;
            width: 100%;
            position: relative;
            min-height: unset;
            padding: 10px 16px;
            box-sizing: border-box;

            div {
                display: flex;
                align-items: center;
            }
        }

        .item {
            display: flex;
            align-items: center;
            padding: 14px 0;
            border-bottom: 1px solid $border_color;
            text-decoration: none;

            @media screen and (max-width: 1080px) {
                padding: 0;
            }

            @media screen and (max-width: 1080px) {
                flex-direction: column;
                border-bottom: none;
                
                &:not(:last-child) {
                    margin-right: 14px;
                }
            }

            @media screen and (max-width: 520px) {
                &:not(:last-child) {
                    margin-right: 10px;
                }
            }

            &.active,
            &:hover {
                cursor: pointer;

                // svg {
                //     fill: $main_green;
                // }

                span {
                    color: $main_green;
                }
            }
            
            svg {
                margin-right: 10px;
                width: 20px;
                height: 20px;
                // fill: rgb(218, 29, 29) !important;
                transition: fill .2s;

                @media screen and (max-width: 1080px) {
                    margin-bottom: 5px;
                    margin-right: 0;
                }
            }

            span {
                color: $white;
                font-size: 16px;
                font-weight: 600;
                transition: color .2s;

                @media screen and (max-width: 1080px) {
                    font-size: 12px;
                }

                @media screen and (max-width: 520px) {
                    font-size: 9px;
                }
            }
        }

        .exit_button {
            display: flex;
            align-items: center;
            width: fit-content;
            margin-left: auto;
            background-color: transparent;
            border: none;
            outline: none;
            transition: opacity .2s;

            @media screen and (max-width: 1080px) {
                display: none;
            }

            &:hover {
                opacity: .8;
                cursor: pointer;
            }

            img {
                width: 20px;
                height: 20px;
                object-fit: contain;
                object-position: center;
                margin-right: 10px;
            }

            span {
                color: $white;
                font-size: 16px;
                font-weight: 600;
                text-decoration-line: underline;
            }
        }
    }
}

.account_container {
    overflow-y: auto;
    width: calc(80% - 20px);
    min-height: calc(100vh - 120px);
    max-height: 100vh;
    padding: 20px;
    background-color: $main_blue;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @media screen and (max-width: 1080px) {
        width: 100%;
        margin-top: 36px;
        box-sizing: border-box;
        max-height: auto;
    }

    .page_name {
        color: $white;
        font-size: 20px;
        font-weight: 500;

        @media screen and (max-width: 520px) {
            font-size: 14px;
        }
    }

    .account_img {
        position: relative;
        margin-bottom: 50px;

        .main_img {
            border-radius: 80px;
            border: 2px solid $main_green;
            width: 80px;
            height: 80px;
            object-fit: contain;
            object-position: center;
        }

        .arrow {
            position: absolute;
            top: 50%;
            right: -25px;
            transform: translateY(-50%);
            transition: opacity .2s;

            &:hover {
                cursor: pointer;
                opacity: .8;
            }
        }
    }

    .action {
        text-align: center;
        color: $main_green;
        text-decoration-line: underline;
        margin-top: 10px;
        transition: color .2s;

        @media screen and (max-width: 520px) {
            font-size: 14px;
        }

        &:hover {
            color: $main_green_hover;
        }
    }
}

.inputs_wrapper {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    width: 100%;

    .item {
        display: flex;
        flex-direction: column;
        width: 100%;
        
        &:not(:last-child) {
            margin-bottom: 20px;
        }

        label {
            color: $white;
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 10px;

            @media screen and (max-width: 520px) {
                font-size: 14px;
            }
        }

        input {
            height: 19px;
            border-radius: 3px;
            outline: none;
            border: none;
            background-color: $white;
            padding: 13px 10px;
            color: $main_blue;
            font-family: Raleway;
            font-size: 16px;

            @media screen and (max-width: 520px) {
                font-size: 14px;
            }
        }

        .input_container {
            display: flex;
            align-items: center;

            &:not(:last-child) {
                margin-bottom: 5px;
            }
            
            input {
                width: 100%;
                max-width: 70px;
                margin-right: 10px;
            }

            span {
                color: $white;
            }
        }
    }
}

.module {
    position: absolute;
    left: 0;
    top: -70px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.500);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;

    .module_wrapper {
        max-width: 426px;
        background-color: #050033;
        border: 1px solid #FF5252;
        border-radius: 5px;
        padding: 32px 20px 20px;

        .content {
            margin-bottom: 72px;
            position: relative;

            img {
                position: absolute;
                left: 0;
                right: 0;
            }

            span {
                display: inline-block;
                margin-left: 40px;
                font-weight: 600;
                font-size: 16px;
                color: $white;

                &.red {
                    color: #FF5252;
                    margin-left: 0;
                }
            }
        }

        .action_block {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            button {
                padding: 10px 16px;
                border: none;
                border-radius: 3px;
                width: fit-content;
                font-weight: 500;
                font-family: 'Raleway';
                transition: opacity .2s;

                &:hover {
                    cursor: pointer;
                    opacity: .8;
                }

                &.left {
                    background-color: $white;
                }

                &.right {
                    background-color: #FF5252;
                    color: $white;
                    margin-left: 20px;
                }
            }
        }
    }
}

.container {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .name {
        color: $white;
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 20px;
    }

    .email {
        color: $white;
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 20px;
    }

    &.preferences_container {
        justify-content: flex-start;
        flex-direction: row; 
        align-items: flex-start;

        @media screen and (max-width: 780px) {
            flex-direction: column;
        }

        .free_wrapper {
            position: fixed;
            right: 45px;
            top: 100px;
            padding: 45px 50px;
            border: 2px solid $main_green;
            border-radius: 5px;
            background-color: $main_blue;
            z-index: 10;
            width: 100%;
            max-width: 405px;
            
            p {
                color: $white;
                text-align: center;
                font-size: 16px;
                font-weight: 600;
                margin-bottom: 20px;
    
                span {
                    color: $main_green;
                    text-decoration: underline;
                }
            }
    
            button {
                padding: 10px;
                background-color: $main_green;
                color: $main_blue;
                font-weight: 700;
                font-size: 16px;
                border: none;
                border-radius: 5px;
                width: 100%;
                font-family: Raleway;
                transition: background-color .2s;
    
                &:hover {
                    cursor: pointer;
                    background-color: $main_green_hover;
                }
            }
        }

        .list_item {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 45%;

            @media screen and (max-width: 520px) {
                width: 100%;
            }

            a {
                padding-bottom: 2px;
                visibility: hidden;
                opacity: 0;
                transition: visibility .2s, opacity .2s;
            }

            &:hover {
                a {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }

        .left_column {
            width: 30%;
            display: flex;
            flex-direction: column;
            margin-right: 100px;

            @media screen and (max-width: 780px) {
                width: 100%;
                margin-right: 0;
                margin-bottom: 16px;
                max-width: unset;
            }
        }

        .preferences_save {
            position: fixed;
            right: 50px;
            bottom: 20px;

            @media screen and (max-width: 1080px) {
                right: 16px;
                bottom: 16px;
            }
        }

        .right_column {
            display: flex;
            flex-wrap: wrap;
            width: calc(70% - 100px);

            &.free {
                opacity: .5;
            }

            @media screen and (max-width: 780px) {
                width: 100%;
            }

            label {
                color: $white;

                span {
                    &:last-child {
                        &:first-letter {
                            text-transform: uppercase;
                        }
                    }
                }
            }

            .inputs_wrapper {
                width: 100%;
                max-width: 500px;
                margin-bottom: 10px;

                &:not(:first-child) {
                    margin-top: 30px;
                }
            }

            .input_block {
                width: 100%;
                position: relative;
                margin-top: 10px;

                input {
                    width: 100%;
                    box-sizing: border-box;
                    height: 45px;
                    padding-right: 40px;
                }

                img {
                    position: absolute;
                    right: 10px;
                    top: 50%;
                    transform: translateY(-50%);
                }

                .autocomplete_list {
                    position: absolute;
                    background-color: white;
                    border: 1px solid #ccc;
                    width: 100%;
                    z-index: 1000;
                    padding: 0;      
                    margin: 0;       
                    list-style: none;
                    max-height: 300px;
                    overflow-y: auto;
                  
                    li {
                      padding: 10px;
                      cursor: pointer;
                  
                      &:hover {
                        background-color: #f0f0f0;
                      }
                    }
                  }
            }

            .item_wrapper {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-direction: row;

                a {
                    padding-bottom: 1px;
                    margin-left: 10px;
                    display: inline-block;
                    visibility: hidden;
                    opacity: 0;
                    transition: visibility .2s, opacity .2s;
                }
    
                &:hover {
                    a {
                        visibility: visible;
                        opacity: 1;
                    }
                }
                
                label {
                    width: fit-content;
                    margin-right: 10px;
                    margin-bottom: 0;
                }

                button {
                    padding: 0;
                    margin: 0;
                    width: fit-content;
                    height: fit-content;
                    background-color: transparent;
                    border: none;
                    height: 20px;
                    transition: opacity .2s;

                    &:hover {
                        opacity: .8;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

.sub_container {
    flex-direction: row;
    justify-content: center;
    // align-items: unset;
    margin-top: 80px;
    margin-bottom: 0;

    @media screen and (max-width: 920px) {
        flex-direction: column;
        margin-top: 16px;
    }

    .card {
        position: relative;
        width: 36%;
        min-height: 480px;
        border-radius: 5px;
        margin: 0 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &:first-child,
        &:last-child {
            width: 32%;
        }

        &:last-child {
            @media screen and (max-width: 920px) {
                order: 1;
            }
        }

        &:first-child {
            @media screen and (max-width: 920px) {
                order: 2;
            }
        }

        &,
        &:first-child,
        &:last-child {
            @media screen and (max-width: 920px) {
                width: 100%;
                min-height: unset;
                margin: 7px 0;
            }
        }

        .mobile_button {
            color: $main_green;
            display: flex;
            align-items: center;
            position: absolute;
            left: 10px;
            bottom: 10px;

            &.active {
                img {
                    transform: rotate(180deg);
                }
            }

            span {
                color: $main_green;
                font-size: 14px;
                font-weight: 600;
                display: inline-block;
                margin-right: 5px;
            }

            img {
                transition: rotate .2s;
            }

            @media screen and (min-width: 921px) {
                display: none;
            }
        }

        .card_text {
            background: linear-gradient(to right, #F1F1F1, #CDCCD6);
            padding: 5px 10px;
            border: 5px;
            position: absolute;
            right: -10px;
            top: 100px;

            span {
                color: $main_blue;
                font-size: 16px;
                font-weight: 700;
            }

            &::after,
            &::before {
                position: absolute;
                right: 0;
                top: -15px;
                content: '';
                width: 0;
                height: 0;
                border: 0 solid transparent;
                border-right-width: 6px;
                border-left-width: 0px;
                border-bottom: 16px solid #CDCCD6;
            }

            &::before {
                transform: scale(1, -1);
                bottom: -15px;
                top: unset;
            }
        }

        &.prem_card {
            border: 4px solid $main_green;

            .special {
                color: $main_green;
            }

            .header {
                .special {
                    color: $main_blue;
                }
            }
        }

        &.free_card {
            .list {
                display: block;
            }
        }

        &.basic_card {
            border: 1px solid $main_green;

            .special {
                color: $main_green;
            }

            .header {
                .special {
                    color: $main_blue;
                }
            }

            .list {
                margin-bottom: 5px;
            }

            .header {
                span {
                    font-size: 20px;
                }
            }

            .button {
                padding-top: 10px;
                padding-bottom: 10px;
                font-size: 16px;
            }

            .bottom_text {
                font-size: 12px;
            }
        }
    }

    .header {
        padding: 25px 16px;
        margin: 7px 7px 40px;
        background: linear-gradient(95deg, rgba(145, 230, 23, 0.10) 4.15%, #91E617 64.62%);
        text-align: center;

        @media screen and (max-width: 920px) {
            margin-bottom: 28px;
        }

        span {
            color: $white;
            font-size: 23px;
            font-weight: 700;
        }
    }

    .price_wrapper {
        color: $white;
        font-size: 20px;
        font-weight: 600;
        margin: 0 auto 45px;
        text-align: center;
        padding: 0 20px;

        @media screen and (max-width: 920px) {
            margin-bottom: 8px;
        }

        &.big_wrapper {
            .price {
                font-size: 55px;

                @media screen and (max-width: 920px) {
                    font-size: 40px;
                }
            }

            .subtext {
                font-size: 16px;

                @media screen and (max-width: 920px) {
                    font-size: 14px;
                }
            }
        }

        .price {
            font-size: 45px;
            font-weight: 700;
        }

        .subtext {
            color: rgba(255, 255, 255, 0.50);
            font-size: 14px;
        }
    }

    .list {
        margin-bottom: 45px;
        padding: 0 20px;

        @media screen and (max-width: 920px) {
            padding: 0 10px;
            margin-top: 20px;

            &:not(.mobile_open) {
                display: none;
            }
        }

        .item {
            display: flex;
            align-items: flex-start;

            &:not(:last-child) {
                margin-bottom: 20px;
            }

            img {
                width: 19px;
                height: 19px;
                object-fit: contain;
                object-position: center;
                margin-right: 10px;

                @media screen and (max-width: 920px) {
                    width: 14px;
                    height: 14px;
                    margin-right: 5px;
                }
            }

            span:not(.special) {
                color: $white;
                font-size: 16px;
                font-weight: 500;

                @media screen and (max-width: 920px) {
                    font-size: 12px;
                }
            }
        }
    }

    .button {
        display: block;
        width: calc(100% - 40px);
        border-radius: 3px;
        background: $main_green;
        padding: 10px 20px;
        font-size: 20px;
        font-weight: 700;
        margin: 0 20px 20px;
        transition: background-color .2s;
        border: none;

        @media screen and (max-width: 920px) {
            width: fit-content;
            margin-right: 10px;
            margin-left: auto;
            margin-bottom: 8px;
            font-size: 16px;
            padding: 10px 16px;
        }
        
        span {
            color: $main_blue;
        }

        &:hover {
            background-color: $main_green_hover;
            cursor: pointer;
        }
    }

    .cancel_button {
        display: block;
        width: calc(100% - 40px);
        border-radius: 3px;
        background: #FF5252;
        padding: 10px 20px;
        font-size: 20px;
        font-weight: 700;
        margin: 0 20px 20px;
        transition: background-color .2s;
        border: none;
        color: #fff;

        @media screen and (max-width: 920px) {
            width: fit-content;
            margin-right: 10px;
            margin-left: auto;
            margin-bottom: 8px;
            font-size: 16px;
            padding: 10px 16px;
        }
        
        span {
            color: #fff;
        }

        &:hover {
            background-color: #FF5252;
            cursor: pointer;
        }
    }

    .button_inactive {
        background: $border_color;

        &:hover {
            background-color: $border_color;
        }
    }

    .bottom_text {
        font-size: 15px;
        color: $main_green;
        font-weight: 700;
        text-decoration-line: underline;
        display: block;
        padding: 0 20px;
        margin-bottom: 33px;
        text-align: center;
        transition: color .2s;

        @media screen and (max-width: 920px) {
            width: fit-content;
            margin-right: 10px;
            margin-left: auto;
            margin-bottom: 10px;
            font-size: 10px;
            padding: 0;
        }

        &:hover {
            color: $main_green_hover;
            cursor: pointer;
        }
    }
}

.save_button {
    width: min-content;
    margin-left: auto;
    border-radius: 3px;
    background: $main_green;
    padding: 10px 24px;
    color: $main_blue;
    font-weight: 700;
    font-family: Raleway;
    transition: background-color .2s;
    border: none;

    &:hover {
        cursor: pointer;
        background-color: $main_green_hover;
    }
}

.save_button_inactive {
    width: min-content;
    margin-left: auto;
    border-radius: 3px;
    background: '#808080';
    padding: 10px 24px;
    color: $main_blue;
    font-weight: 700;
    font-family: Raleway;
    transition: background-color .2s;
    border: none;
}

.dropdown {
    position: relative;
    display: inline-block;
    top: -50px;
}

.dropdown_menu {
    width: 140px;
    position: absolute;
    left: 0;
    top: 30px;
    background-color: $main_blue;
    border: 3px solid $main_green;
    border-radius: 10px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

.dropdown_item {
    font-weight: 600;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    color: #fff;

    &:hover {
        cursor: pointer;
    }
}

.red {
    color: #FF5252 !important;
}

.bank_container {
    margin-top: 10px;
    position: relative;

    .item_action {
        position: absolute;
        right: 6px;
        top: -30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 84px;
        margin-left: 10px;

        button {
            border-radius: 3px;
            border: 2px solid $main_green;
            background: $main_blue;
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
                cursor: pointer;
            }

            span {
                color: $main_green;
                font-family: Raleway;
                font-size: 20px;
                font-weight: 600;
            }

            &.down {
                border: 1px solid #FF5252;

                span {
                    color: #FF5252;
                    font-family: Raleway;
                    font-weight: 400;
                }
            }
        }
    }
    
    .modular_wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
    
        .green {
            color: $main_green !important;
        }
    
        .red {
            color: #FF5252;
        }
    
        .red_border {
            border-color: #FF5252 !important;
        }
    
        .modular_item {
            width: 100%;
            background: $main_blue;
            box-sizing: border-box;

            .balance_wrapper {
                max-width: 142px;
                margin: 0;

                &.withdraw_block {
                    margin: 50px 0 30px;
                }
                
                & > span {
                    color: $white;
                    font-size: 16px;
                    font-weight: 600;
                    margin-bottom: 10px;
                    display: inline-block;
                }

                .value {
                    border-radius: 3px;
                    border: 2px solid $main_green;
                    padding: 13px 10px;
                    display: flex;
                    align-items: center;

                    span,
                    input {
                        color: $white;
                        font-family: Raleway;
                        font-size: 16px;
                        font-weight: 600;
                    }

                    input {
                        width: 100%;
                        border: none;
                        background-color: $main_blue;
                        margin: 0 10px;
                    }
                }                
            }
    
            .top {
                display: flex;
                justify-content: space-between;
    
                .name_wrapper {
                    display: flex;
                    flex-direction: column;
    
                    .subname {
                        color: $white;
                        font-size: 16px;
                        font-weight: 600;
                    }
                }
            }
    
            .action_wrapper {
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
                padding-bottom: 20px;
                margin-bottom: 10px;
                max-width: 600px;
    
                button {
                    padding: 10px 50px;
                    border-radius: 3px;
                    background-color: $main_green;
                    border: none;
                    transition: opacity .2s;
    
                    &:hover {
                        cursor: pointer;
                        opacity: .8;
                    }
    
                    span {
                        color: $main_blue;
                        font-family: Raleway;
                        font-size: 16px;
                        font-weight: 700;
                    }
                }
    
                .input_wrapper {
                    margin-right: 20px;
                   
                    & > span {
                        color: $white;
                        font-size: 16px;
                        font-weight: 600;
                        margin-bottom: 10px;
                        display: inline-block;
                    }
    
                    .value {
                        border-radius: 3px;
                        border: 2px solid $main_green;
                        padding: 13px 10px;
                        display: flex;
                        align-items: center;
    
                        span,
                        input {
                            color: $white;
                            font-family: Raleway;
                            font-size: 16px;
                            font-weight: 600;
                        }
    
                        input {
                            width: 100%;
                            border: none;
                            background-color: $main_blue;
                            margin: 0 10px;
                        }
                    } 
                    
                    &.white {
                        .value {
                            background-color: $white;
                            border-color: $white;
    
                            span,
                            input {
                                color: $main_blue;
                            }
    
                            input {
                                background-color: $white;
                            }
                        }
                    }
                }
            }

            .middle_wrapper {
                margin-top: 50px;
                margin-bottom: 30px;
                display: flex;
            }
    
            .table_wrapper {
                border-top: 1px solid rgba(255, 255, 255, 0.50);
                padding-top: 20px;

                .table_header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
    
                .name {
                    color: $white;
                    font-size: 16px;
                    font-weight: 700;
                }
    
                .dropdown_button {
                    span {
                        color: $white;
                        font-size: 12px;
                        font-weight: 600;
                    }
    
                    img {
                        width: 14px;
                        height: 14px;
                        object-fit: contain;
                        object-position: center;
                    }
                }
            }
    
            .modular_table {
                width: 100%;
                margin-top: 0;
            
                table {
                    border-collapse: separate;
                    border-spacing: 0 10px;
                }
            
                & > div {
                    background-color: transparent;
                }
            
                th, td {
                    font-family: Raleway;
                    border-bottom: none;
                }
            
                thead {
                    th {
                        color: $white;
                        padding: 3px 3px 0;
                    }
                }
            
                tbody {
    
                    tr {
                        transition: opacity .2s;
            
                        &:hover {
                            cursor: pointer;
                            opacity: .9;
                        }
            
                        td {
                            padding: 7px 20px;
                            color: $white;
                            background: rgba(255, 255, 255, 0.20);
            
                            &:first-child {
                                border-top-left-radius: 3px;
                                border-bottom-left-radius: 3px;
                            }
                            
                            &:last-child {
                                border-top-right-radius: 3px;
                                border-bottom-right-radius: 3px;
                            }
                        }
                    }
                }
            }

            .list_wrapper {
                width: 100%;
                margin-left: 60px;
                max-width: 400px;
                width: calc(100% - 54px);

                & > span {
                    color: $white;
                    font-size: 16px;
                    font-weight: 600;
                    display: inline-block;
                    margin-bottom: 10px;
                }
            }
    
            .value_list {
                display: flex;
                align-items: center;
                justify-content: space-between;
    
                button {
                    width: calc(33.333% - 10px);
                    padding: 10px;
                    border-radius: 20px;
                    background: linear-gradient(90deg, $main_green 0%, rgba(145, 230, 23, 0.50) 100%);
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border: none;
                    transition: opacity .2s;
    
                    &:hover {
                        cursor: pointer;
                        opacity: .8;
                    }
    
                    span {
                        color: $main_blue;
                        font-family: Raleway;
                        font-size: 16px;
                        font-weight: 600;
    
                        &:first-child {
                            display: inline-block;
                            margin-right: 10px;
                        }
                    }
                }
            }
        }

        @media screen and (max-width: 760px) {
            span, input {
                font-size: 11px !important;
            }
            
            .action_wrapper,
            .middle_wrapper {
                flex-direction: column;
                align-items: center !important;
            }

            .middle_wrapper {
                .list_wrapper {
                    margin: 0;
                    margin-top: 10px;
                }
            }
                   
            button {
                margin-top: 10px;
            }
        }
    }
}

.account_save {
    position: fixed;
    right: 50px;
    bottom: 20px;

    @media screen and (max-width: 1080px) {
        right: 16px;
        bottom: 16px;
    }
}