/* Styles for displaying income */
.react-calendar__tile p {
    font-size: 0.75em; /* Font size */
    margin: 0; /* Removing external padding */
}

.react-calendar {
    background-color: #505050 !important;
    color: white;

    width: 100% !important; /* Occupies 100% of the parent's width */
    
    border: none !important; /* Remove the border if there is one */
}
  
/* Make sure the text inside the calendar is clearly visible against a dark background */
.react-calendar__tile {
    margin: 5px !important;
	max-width: calc(14.28% - 10px) !important;
    box-sizing: border-box !important;
    color: #000 !important;
    font-size: 12px !important; /* Setting the font size */
    font-weight: 600 !important; /* Setting the font weight */
}
  
/* change the styles for inactive or active days */
.react-calendar__tile--active, .react-calendar__tile--hasActive {
    background: #666; /* Lighter gray for an active day */
}
  
.react-calendar__tile--now {
    background: #777; /* An even lighter gray for the day */
}
  
.react-calendar__navigation button {
    background-color: transparent;
    color: white;
}

.react-calendar__navigation button:hover {
    background-color: #383838 !important; /* Change to your desired background color */
}
  
/* Change header style if needed */
.react-calendar__navigation {
    background-color: #505050; /* Slightly darker gray for the title */
}

  /* Styles for weekday headers */
.react-calendar__month-view__weekdays {
    background-color: #505050;
    text-align: center;
}
  
.react-calendar__month-view__weekdays__weekday {
    border: none; /* Remove the border if there is one */
}
  
/* Styles for every day */
.react-calendar__month-view__days__day {
    border-radius: 5px; /* Rounded corners */
}

/* Styles for days that do not belong to the current month (inactive days) */
.react-calendar__month-view__days__day--neighboringMonth {
    background-color: #757575; /* Gray background for inactive days */
}

.react-calendar__navigation {
    border-bottom: none; /* Removes underscores/borders in navigation block */
}
  
.react-calendar__month-view__weekdays {
    border-bottom: none; /* Removes underscores/borders on day names block */
}
  
.react-calendar__month-view__days {
    border: none; /* Removes borders around days if there are any. */
}
