@import './colors.scss';

.freepage {
    width: 100%;
    height: calc(100vh - 150px);
    background-color: $main_blue;
    margin: 20px 20px 0;
    padding: 20px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    .freepage_wrapper {
        max-width: 404px;
        padding: 45px 50px;
        border: 2px solid $main_green;
        border-radius: 5px;
        text-align: center;

        span {
            font-weight: 600;
            color: $white;
            font-size: 16px;

            .link_freepage {
                color: $main_green;
                text-decoration: underline;
                transition: color .2s;

                &:hover {
                    color: $main_green_hover;
                }
            }
        }

        .link_btn {
            border: none;
            border-radius: 3px;
            background-color: $main_green;
            width: 100%;
            margin-top: 20px;
            display: block;
            padding: 10px;
            text-align: center;
            font-weight: 700;
            color: $main_blue;
            text-decoration: none;

            transition: background-color .2s;

            &:hover {
                background-color: $main_green_hover;
            }
        }
    }
}