$main_blue: #050033;
$main_green: #91E617;

$main_yellow: #FFF700;
$delete: #F00;

$main_green_hover: #c5ff74;

$border_color: rgba(255, 255, 255, 0.30);

$white: #ffffff;
$black: #000000;

$premium_dark: #A82C5E;
$premium: #EB488A;
$basic_dark: #1D00FF;
$basic: #00A3FF;