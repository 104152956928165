@import './colors.scss';

.howtopage {
    width: 100%;
    //height: calc(100vh - 150px);
    background-color: $main_blue;
    margin: 20px 20px;
    //padding: 20px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    .howtopage_wrapper {
        color: #fff;
        font-size: 20px;
        
        padding: 45px 50px;   
        line-height: 1.5;     
        a {
            color: #fff;
        }

        .img_container {
            width: 100%;
            display: flex;
            justify-content: center;
            padding-top: 10px;
            padding-bottom: 10px;
            img {
                border: 1px solid #fff;
                max-width: 100%;
                height: auto;
                
            }

            @media (min-width: 1200px) {
                img {
                    max-width: 1100px; /* Ограничивает ширину изображения до 1100px на экранах шире 1200px */
                }
            }
        }
    }

    @media (max-width: 1200px) {
        .howtopage_wrapper {
            padding: 20px;
        }
    }
}